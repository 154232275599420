import classes from './RichText.view.component.module.scss';

import React, { useMemo, useRef, useState } from 'react';
import Flex from '../Flex';
import cnj from '../utils/cnj';
import { linkify } from './richTextApi';
import Typography from '../Typography';
import BaseButton from '../Button/BaseButton';
import type { TypographyProps, TextProps } from '../Typography';
import { flushSync } from 'react-dom';
import { removeRichTextBreakLines, useTranslation } from '@shared/utils-pkg';
import { getFirstBackgroundParent } from '@shared/utils/getFirstBackgroundParent';

interface IRichTextView {
  typographyProps?: Omit<TypographyProps, 'children'>;
  html: string;
  onMentionClick?: (
    username: string,
    id: string,
    type: 'pages' | 'people'
  ) => void;
  onHashtagClick?: (hashtag: string) => void;
  className?: string;
  showMore: boolean;
  row?: number;
  moreButtonVisible?: boolean;
  seeMoreClassName?: string;
  seeMoreTextProps?: TextProps;
}

const RichTextView = ({
  typographyProps = {},
  html = '',
  onMentionClick,
  onHashtagClick,
  className,
  showMore,
  row = 4,
  moreButtonVisible = true,
  seeMoreClassName,
  seeMoreTextProps = {},
}: IRichTextView): any => {
  const [object, setObject] = useState();
  const [seeAll, setSeeAll] = useState(false);
  const containerRef = useRef<HTMLElement>();
  const hasMore = useRef(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    hasMore.current =
      (containerRef?.current?.scrollHeight || 0) >
      (containerRef.current?.clientHeight || 0);
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.stopPropagation();
    const target = e?.target as Element;
    const isMentionChild = target.getAttribute('contenteditable') === 'false';

    if (!isMentionChild) return;
    const element = target?.parentNode as Element;

    const denotation = element.getAttribute('data-denotation-char');
    const data = element.getAttribute('data-value');
    const username = element.getAttribute('data-username');
    const objId = element.getAttribute('data-userid');
    const userType = element.getAttribute('data-user-type') as
      | 'PAGE'
      | 'PERSON';
    flushSync(() => setObject({ id: objId, username, userType }));

    if (
      !!onMentionClick &&
      (denotation === '@' || (denotation === '' && !!objId))
    ) {
      onMentionClick?.(
        data as any,
        objId,
        userType === 'PAGE' ? 'pages' : 'people'
      );
      return;
    }
    if (!!onHashtagClick && denotation === '#') {
      onHashtagClick?.(data?.toLowerCase() as any, e);
      return;
    }
  };

  const val = useMemo(() => removeRichTextBreakLines(linkify(html)), [html]);
  const handleSeeMore = () => {
    setSeeAll(true);
  };

  return (
    <>
      <Flex className={cnj(classes.mainContainer, className)}>
        <Typography {...typographyProps}>
          <Flex
            onClick={handleClick}
            ref={containerRef}
            as="span"
            className={cnj(
              'richTextViewContent',
              classes.richTextViewContainer,
              (!!onMentionClick || !!onHashtagClick) &&
                classes.richTextWithMentionHover,
              !!onMentionClick && classes.richTextWithMentionClick,
              classes.outerWrapper
            )}
            style={{
              WebkitLineClamp: row,
              lineClamp: row,
              display: seeAll || !showMore ? 'initial' : undefined,
            }}
            dangerouslySetInnerHTML={{ __html: val }}
          />
        </Typography>
        {hasMore.current && showMore && !seeAll && moreButtonVisible && (
          <Flex flexDir="row" className={classes.seeMoreContainer}>
            <BaseButton
              className={cnj(
                classes.readMoreBtn,
                'read-more-btn',
                seeMoreClassName
              )}
              onClick={handleSeeMore}
              ref={(refs) => {
                if (!refs) return;
                const bg = getFirstBackgroundParent(refs);
                refs.style.backgroundColor = bg;
              }}
            >
              <Typography
                color="brand"
                {...seeMoreTextProps}
                className={cnj(
                  classes.readMoreText,
                  seeMoreTextProps.className
                )}
              >
                {`... ${t('see_more')}`}
              </Typography>
            </BaseButton>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default RichTextView;
