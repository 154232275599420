import useGetAppObject from '@shared/hooks/useGetAppObject';
import React from 'react';
import type { RoleType } from '@lobox/utils';

type UseGetRoleType = {
  roles: Array<RoleType>;
};

const useGetRole = (): UseGetRoleType => {
  const { businessPage } = useGetAppObject();
  const myMemberships = businessPage?.myMemberships || [];

  const roles = React.useMemo(
    () =>
      myMemberships?.reduce((prev, curr) => {
        if (curr.status === 'ACCEPTED') {
          return [...prev, curr.role];
        } else {
          return prev;
        }
      }, []),
    [myMemberships?.join('')]
  );

  return { roles };
};

export default useGetRole;
