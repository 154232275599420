import React from 'react';
import NotFound from '@lobox/uikit/NotFound';
import { useTranslation } from '@lobox/utils';
import Private from '@shared/svg/Private';

const PermissionDenied = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <NotFound
      title={t('permission_denied')}
      message={t('permission_denied_msg')}
      image={<Private />}
    />
  );
};

export default PermissionDenied;
