export const SCOPES = {
  casAssignRole: 'casAssignRole',
  canEditProfile: 'canEditProfile',
  canFollowUnFollow: 'canFollowUnFollow',
  canCreatePost: 'canCreatePost',
  canSeeHomeScreen: 'canSeeHomeScreen',
  canSeePeopleScreen: 'canSeePeopleScreen',
  canSeePagesScreen: 'canSeePagesScreen',
  canSeeMessagesScreen: 'canSeeMessagesScreen',
  canSeeInviteScreen: 'canSeeInviteScreen',
  canSeeSettingsScreen: 'canSeeSettingsScreen',
  canSeeSearchPeople: 'canSeeSearchPeople',
  canSeeSearchPages: 'canSeeSearchPages',
  canSeeSearchPost: 'canSeeSearchPost',
  canSeeSearchHashtag: 'canSeeSearchHashtag',
  canSeePeopleFollowerTab: 'canSeePeopleFollowerTab',
  canSeePeopleFollowingTab: 'canSeePeopleFollowingTab',
  canSeeProfile3DotMenu: 'canSeeProfile3DotMenu',
  canSeeProfileHeaderRightActions: 'canSeeProfileHeaderRightActions',
  canSeeDashboardTab: 'canSeeDashboardTab',
  canSeeAboutTab: 'canSeeAboutTab',
  canSeeFeedTab: 'canSeeFeedTab',
  canSeePagesTab: 'canSeePagesTab',
  canSeeHashtagTab: 'canSeeHashtagTab',
  canSeeCollectionsTab: 'canSeeCollectionsTab',
  canSeeNotificationScreen: 'canSeeNotificationScreen',
  canSeePlusAction: 'canSeePlusAction',
  canDeletePage: 'canDeletePage',
  canCreateJob: 'canCreateJob',
  canSeeSearchJobs: 'canSeeSearchJobs',
} as const;
