import classes from './toast.message.module.scss';

import React from 'react';
import { useTranslation } from '@lobox/utils';
import Typography from '../Typography';
import cnj from '../utils/cnj';
import Flex from '../Flex';

export interface ToastProps {
  hasTitle: boolean;
  message?: string | (() => React.ReactElement);
}

const ToastMessage = ({ hasTitle, message }: ToastProps) => {
  const { t } = useTranslation();

  return message ? (
    <Flex className={cnj(classes.textWrapper, hasTitle && classes.paddingTop)}>
      {typeof message === 'string' ? (
        <Typography height={21} size={15} color="primaryText">
          {t(message)}
        </Typography>
      ) : (
        message()
      )}
    </Flex>
  ) : null;
};

export default ToastMessage;
