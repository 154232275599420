import React from 'react';
import { toast } from 'react-toastify';
import Toast from './index';
import type { ToastProps } from './index';

const useToast =
  () =>
  ({ icon, title, message, type, id, actionButton, autoClose }: ToastProps) =>
    toast(
      <Toast
        {...{
          title,
          message,
          icon,
          type,
          actionButton,
        }}
      />,
      { autoClose, toastId: id }
    );

export default useToast;
