import classes from './index.module.scss';

import React from 'react';
import { useTranslation } from '@lobox/utils';
import IconButton from '../Button/IconButton';
import Typography from '../Typography';
import Icon from '../Icon';
import Flex from '../Flex';
import ToastMessage from './toast.message';

export interface ToastProps {
  type?: 'error' | 'success' | 'warning';
  title?: string;
  message?: string | (() => React.ReactElement);
  icon?: string;
  id?: string;
  actionButton?: () => React.ReactNode;
  autoClose?: number | false | undefined;
}

const CustomToast = ({
  title,
  message,
  icon,
  type = 'error',
  actionButton,
}: ToastProps) => {
  const { t } = useTranslation();

  return (
    <Flex className={classes.snackBarContainer}>
      <Flex className={classes.snackBarContent}>
        {icon && (
          <Icon name={icon} color={type} className={classes.toastIcon} />
        )}
        <Flex className={classes.titleWrapper}>
          {title ? (
            <Typography height={20} font="700" color="thirdText" size={16}>
              {t(title)}
            </Typography>
          ) : (
            <ToastMessage hasTitle={false} message={message} />
          )}
        </Flex>
        <Flex className={classes.actionWrapper}>
          {actionButton ? (
            actionButton()
          ) : (
            <IconButton type="fas" name="times" size="sm" />
          )}
        </Flex>
      </Flex>
      {title && message && (
        <ToastMessage hasTitle={!!title} message={message} />
      )}
    </Flex>
  );
};

export default CustomToast;
