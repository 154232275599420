import type { ScopeKeys, ScopeType } from '@shared/types/permission';

type HasPermissionProps = {
  permissions: Array<ScopeType>;
  scopes: Array<ScopeType>;
};
const hasPermission = ({
  permissions,
  scopes,
}: HasPermissionProps): boolean => {
  const scopesMap: { [key in ScopeKeys]?: boolean } = {};
  scopes?.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions.some((permission) => scopesMap[permission]);
};

export default hasPermission;
