import classes from './EmptyState.module.scss';

import React, { cloneElement } from 'react';
import Card from '@lobox/uikit/Card';
import cnj from '@lobox/uikit/utils/cnj';
import type { ButtonProps } from '@lobox/uikit/Button';
import type { TypographyProps } from '@lobox/uikit/Typography';

export interface EmptyStateProps {
  className?: string;
  hasMargin?: boolean;
  image: React.ReactNode;
  caption: string;
  message: string | React.ReactNode;
  action?: {
    title: string;
    onClick?: (...args: any[]) => any;
  };
  captionProps?: Omit<TypographyProps, 'children'>;
  messageProps?: Omit<TypographyProps, 'children'>;
  actionProps?: Omit<ButtonProps, 'children'>;
  contentClassName?: string;
  imageClassName?: string;
  imageContainerClassName?: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  className,
  hasMargin,
  image,
  caption,
  message,
  action,
  captionProps,
  messageProps,
  actionProps,
  contentClassName,
  imageClassName,
  imageContainerClassName,
}) => {
  const Image = cloneElement(image, {
    className: cnj(classes.image1, imageClassName),
  });

  return (
    <Card
      className={cnj(
        classes.emptyStateRoot,
        hasMargin && classes.hasMargin,
        className
      )}
      caption={caption}
      image={Image}
      message={message}
      actionTitle={action?.title}
      action={action?.onClick}
      hoverColor="transparent"
      captionProps={captionProps}
      messageProps={messageProps}
      actionProps={actionProps}
      contentClassName={contentClassName}
      imageContainerClassName={imageContainerClassName}
    />
  );
};

export default EmptyState;
