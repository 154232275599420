import classes from './index.module.scss';

import React from 'react';
import cnj from '../utils/cnj';
import type { TypographyProps } from '../Typography';
import Typography from '../Typography';
import type { ButtonProps } from '../Button';
import Button from '../Button';
import Paper from '../Paper';
import Flex from '../Flex';

interface CardProps {
  title?: string;
  subTitle?: React.ReactNode;
  caption?: string;
  message?: string;
  image?: any;
  className?: any;
  actionTitle?: string;
  hoverColor?: string;
  hasTitleBorder?: boolean;
  contentClassName?: string;
  action?: Function;
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse';
  noHover?: boolean;
  titleClassName?: string;
  subTitleClassName?: string;
  titleContainerClassName?: string;
  imageContainerClassName?: string;
  captionProps?: Omit<TypographyProps, 'children'>;
  subtitleProps?: Omit<TypographyProps, 'children'>;
  messageProps?: Omit<TypographyProps, 'children'>;
  actionProps?: Omit<ButtonProps, 'children'>;
}

const Card: React.FC<CardProps> = ({
  title,
  subTitle,
  caption,
  message,
  image,
  className,
  contentClassName,
  actionTitle,
  action,
  direction = 'column',
  hoverColor,
  hasTitleBorder,
  noHover,
  titleClassName,
  subTitleClassName,
  titleContainerClassName,
  imageContainerClassName,
  captionProps = {},
  messageProps = {},
  actionProps = {},
  subtitleProps,
}) => {
  const isRow = direction === 'row';
  const isRowReverse = direction === 'row-reverse';
  const isColumnReverse = direction === 'column-reverse';
  const hasTitle = title || subTitle;

  return (
    <Paper
      {...{
        title,
        subTitle,
        subtitleProps,
        className,
        contentClassName,
        direction,
        hoverColor,
        hasTitleBorder,
        noHover,
        titleClassName,
        subTitleClassName,
        titleContainerClassName,
      }}
    >
      {image && (
        <Flex
          className={cnj(
            classes.imageContainer,
            isRow && classes.imageContainerRow,
            isRowReverse && classes.imageContainerRowReverse,
            isColumnReverse && classes.imageContainerColumnReverse,
            hasTitle && classes.imageContainerHasTitle,
            imageContainerClassName
          )}
        >
          <Flex
            className={cnj(
              classes.imageWrap,
              isRow && classes.imageWrapRow,
              isColumnReverse && classes.imageWrapColumnReverse,
              isRowReverse && classes.imageWrapRowReverse
            )}
          >
            {image}
          </Flex>
        </Flex>
      )}
      {caption && (
        <Typography
          isWordWrap
          {...captionProps}
          className={cnj(classes.caption, captionProps.className)}
        >
          {caption}
        </Typography>
      )}
      {message && (
        <Typography
          isWordWrap
          {...messageProps}
          className={cnj(classes.message, messageProps.className)}
        >
          {message}
        </Typography>
      )}
      {(action || actionTitle) && (
        <Button
          schema="semi-transparent"
          onClick={action as any}
          label={actionTitle}
          {...actionProps}
          className={cnj(classes.action, actionProps.className)}
        />
      )}
    </Paper>
  );
};

export default Card;
