import {
  MAIN_CENTER_WRAPPER_ID,
  TAB_PAGES_HEADER_SEARCH_ID,
} from '@lobox/utils';
import errorMsg from './errorMsg';

export { TAB_PAGES_HEADER_SEARCH_ID, MAIN_CENTER_WRAPPER_ID, errorMsg };

export const pageStatus = {
  UNPUBLISHED: 'UNPUBLISHED',
  PUBLISHED: 'PUBLISHED',
} as const;
export const followStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
};

export const TOP_BAR_SEARCH_INPUT = 'top-bar-search-input';

export const TOP_BAR_SEARCH_INPUT_TRASH = 'top-bar-search-input-trash';

export const TOP_BAR_TRASH_ICON = 'top-bar-trash-icon';

export const AUTO_COMPLETE_WRAPPER_ID = 'auto-complete-wrapper-id';

export const SEARCH_BUTTON_ID = 'top-bar-search-button-id';

export const TOP_BAR_ID = 'top-bar-id';

export const OPEN_DRAWER_EVENT = 'OPEN_DRAWER_EVENT';

export const PROFILE_SCROLL_WRAPPER = 'profileScrollWrapper';

export const SCHEDULE_CALENDAR_RIGHT_SIDE_WRAPPER =
  'SCHEDULE_CALENDAR_RIGHT_SIDE_WRAPPER';

export const CHAT_WRAPPER_ID = 'chat-wrapper-id';

export const CHAT_ROOM_ITEM_CLASS = 'chat-room-item';

export const INIT_API_SIZE = 6;

export const DESCRIPTION_MAX_LENGTH = 3000;

export const SCHEDULE_EVENT_DESCRIPTION_MAX_LENGTH = 8000;

export const BLOCK_TIMEOUT = 3000;

export const TOP_BAR_ITEM = 'top-bar-item';

export const TOP_BAR_NOTIFICATION_BUTTON = 'top-bar-notification-button';

export const TOP_OF_LIST_INFO_CARD = 'TOP_OF_LIST_INFO_CARD';

export const AUTO_COMPLETE_OPTIONS_ID = 'autocomplete-options';

export const CAROUSEL_SKELETON_NUMBERS = 10;
export const carouselPlaceholderData = Array(CAROUSEL_SKELETON_NUMBERS)
  .fill(0)
  .map((_, i) => ({ id: `ph0${i}`, title: ' ', imageUrl: '' }));

export const SKELETON_NUMBERS = 6;

export const SocialConnectionsTabs = {
  followers: 'FOLLOWERS',
  following: 'FOLLOWING',
  mutuals: 'MUTUALS',
  worksHere: 'WORKS_HERE',
} as const;

export const placeholderData = (
  uniqueName: string,
  number = SKELETON_NUMBERS
): Array<{
  id: string;
}> =>
  Array(number)
    .fill(0)
    .map((_, i) => ({ id: `sk_${uniqueName}_0${i}` }));
