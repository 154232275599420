import { PAGE_ROLES } from '@lobox/utils';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import type { RoleType } from '@lobox/utils';

const PERMISSIONS: {
  [key in RoleType]: Array<any>;
} = {
  [PAGE_ROLES.OWNER.value]: [],
  [PAGE_ROLES.ADMIN.value]: [
    SCOPES.casAssignRole,
    SCOPES.canEditProfile,
    SCOPES.canFollowUnFollow,
    SCOPES.canCreatePost,
    SCOPES.canSeeHomeScreen,
    SCOPES.canSeePagesScreen,
    SCOPES.canSeePeopleScreen,
    SCOPES.canSeeMessagesScreen,
    SCOPES.canSeeSettingsScreen,
    SCOPES.canSeeInviteScreen,
    SCOPES.canSeeSearchPeople,
    SCOPES.canSeeSearchPages,
    SCOPES.canSeeSearchPost,
    SCOPES.canSeeSearchHashtag,
    SCOPES.canSeePeopleFollowingTab,
    SCOPES.canSeePeopleFollowerTab,
    SCOPES.canSeeProfile3DotMenu,
    SCOPES.canSeeProfileHeaderRightActions,
    SCOPES.canSeeDashboardTab,
    SCOPES.canSeeAboutTab,
    SCOPES.canSeeFeedTab,
    SCOPES.canSeePagesTab,
    SCOPES.canSeeHashtagTab,
    SCOPES.canSeeCollectionsTab,
    SCOPES.canSeeNotificationScreen,
    SCOPES.canSeePlusAction,
    SCOPES.canCreateJob,
    SCOPES.canSeeSearchJobs,
  ],
  [PAGE_ROLES.EDITOR.value]: [
    SCOPES.canEditProfile,
    SCOPES.canFollowUnFollow,
    SCOPES.canCreatePost,
    SCOPES.canSeeHomeScreen,
    SCOPES.canSeePagesScreen,
    SCOPES.canSeePeopleScreen,
    SCOPES.canSeeMessagesScreen,
    SCOPES.canSeeSettingsScreen,
    SCOPES.canSeeInviteScreen,
    SCOPES.canSeeSearchPeople,
    SCOPES.canSeeSearchPages,
    SCOPES.canSeeSearchPost,
    SCOPES.canSeeSearchHashtag,
    SCOPES.canSeePeopleFollowingTab,
    SCOPES.canSeePeopleFollowerTab,
    SCOPES.canSeeProfile3DotMenu,
    SCOPES.canSeeProfileHeaderRightActions,
    SCOPES.canSeeDashboardTab,
    SCOPES.canSeeAboutTab,
    SCOPES.canSeeFeedTab,
    SCOPES.canSeePagesTab,
    SCOPES.canSeeHashtagTab,
    SCOPES.canSeeCollectionsTab,
    SCOPES.canSeeNotificationScreen,
    SCOPES.canSeePlusAction,
    SCOPES.canCreateJob,
    SCOPES.canSeeSearchJobs,
  ],
  [PAGE_ROLES.RECRUITER.value]: [
    SCOPES.canSeePeopleScreen,
    SCOPES.canSeeMessagesScreen,
    SCOPES.canSeeSearchPeople,
    SCOPES.canSeeAboutTab,
    SCOPES.canSeeProfileHeaderRightActions,
    SCOPES.canSeeProfile3DotMenu,
  ],
  [PAGE_ROLES.EXPERT.value]: [
    SCOPES.canSeePeopleScreen,
    SCOPES.canSeeSearchPeople,
    SCOPES.canSeeAboutTab,
    SCOPES.canSeeProfile3DotMenu,
  ],
};

export default PERMISSIONS;
