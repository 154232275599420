import React from 'react';
import Svg from '@shared/svg/FeedEmptyState';
import EmptyState from '../EmptyState/EmptyState';

interface IFeedCardEmpty {
  className?: string;
  caption: string;
  description: string;
  action?: {
    title: string;
    onClick: () => void;
  };
}

const FeedCardEmpty: React.VFC<IFeedCardEmpty> = ({
  className,
  caption,
  description,
  action,
}) => (
  <EmptyState
    className={className}
    image={<Svg />}
    hasMargin
    caption={caption}
    message={description}
    action={action}
  />
);

export default FeedCardEmpty;
