import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

interface FeedEmptyStateProps {
  className?: string;
}

const FeedEmptyState: React.FC<FeedEmptyStateProps> = ({ className }) => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      width={390}
      height={243}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity={0.4}>
        <path
          d="M8.874 50.748A8.36 8.36 0 01.5 42.374 8.36 8.36 0 018.874 34a8.36 8.36 0 018.374 8.374c.075 4.636-3.739 8.374-8.374 8.374zm0-16.524c-4.486 0-8.15 3.664-8.15 8.15 0 4.486 3.664 8.15 8.15 8.15 4.486 0 8.15-3.664 8.15-8.15 0-4.486-3.664-8.15-8.15-8.15z"
          fill="#606060"
        />
        <path
          d="M8.875 40.804c-.972 0-1.72-.747-1.72-1.72 0-.971.748-1.719 1.72-1.719s1.72.748 1.72 1.72c0 .897-.748 1.72-1.72 1.72zM12.464 46.71H5.361a.16.16 0 01-.15-.149v-1.495a3.67 3.67 0 013.665-3.664 3.67 3.67 0 013.663 3.664v1.495c.15.075.075.15-.075.15zM169.874 70.746a8.36 8.36 0 01-8.374-8.374 8.36 8.36 0 018.374-8.374 8.36 8.36 0 018.374 8.374c.075 4.635-3.739 8.374-8.374 8.374zm0-16.524c-4.486 0-8.15 3.664-8.15 8.15 0 4.486 3.664 8.15 8.15 8.15 4.486 0 8.15-3.664 8.15-8.15 0-4.486-3.664-8.15-8.15-8.15z"
          fill="#606060"
        />
        <path
          d="M169.875 60.803a1.691 1.691 0 01-1.719-1.72c0-.972.747-1.72 1.719-1.72.972 0 1.72.748 1.72 1.72 0 .897-.748 1.72-1.72 1.72zM173.464 66.709h-7.103c-.074 0-.149-.075-.149-.15v-1.495a3.67 3.67 0 013.663-3.664 3.67 3.67 0 013.664 3.664v1.495c.15.075.075.15-.075.15zM30.874 113.745a8.36 8.36 0 01-8.374-8.374 8.36 8.36 0 018.374-8.374 8.36 8.36 0 018.374 8.374c.075 4.636-3.738 8.374-8.374 8.374zm0-16.524c-4.486 0-8.15 3.664-8.15 8.15 0 4.486 3.664 8.15 8.15 8.15 4.486 0 8.15-3.664 8.15-8.15 0-4.486-3.664-8.15-8.15-8.15z"
          fill="#606060"
        />
        <path
          d="M30.875 103.801c-.972 0-1.72-.748-1.72-1.72s.748-1.72 1.72-1.72 1.72.748 1.72 1.72c0 .897-.748 1.72-1.72 1.72zM34.464 109.707h-7.103c-.074 0-.15-.075-.15-.15v-1.495a3.67 3.67 0 013.665-3.664 3.67 3.67 0 013.663 3.664v1.495c.15.075.075.15-.075.15zM42.5 81.998a5.99 5.99 0 01-6-6c0-3.321 2.679-6 6-6s6 2.679 6 6c.054 3.322-2.679 6-6 6zm0-11.84c-3.214 0-5.84 2.626-5.84 5.84s2.626 5.84 5.84 5.84 5.84-2.626 5.84-5.84-2.626-5.84-5.84-5.84z"
          fill="#606060"
        />
        <path
          d="M42.5 74.874a1.212 1.212 0 01-1.231-1.233c0-.696.535-1.232 1.232-1.232.696 0 1.232.536 1.232 1.232 0 .643-.536 1.233-1.232 1.233zM45.072 79.105h-5.089a.115.115 0 01-.107-.107v-1.071a2.63 2.63 0 012.625-2.625 2.63 2.63 0 012.625 2.625v1.071c.107.054.053.107-.054.107zM7 86.997a4.493 4.493 0 01-4.5-4.5c0-2.491 2.009-4.5 4.5-4.5s4.5 2.009 4.5 4.5c.04 2.491-2.009 4.5-4.5 4.5zm0-8.88a4.39 4.39 0 00-4.38 4.38A4.39 4.39 0 007 86.877a4.39 4.39 0 004.38-4.38A4.39 4.39 0 007 78.117z"
          fill="#606060"
        />
        <path
          d="M7 81.653a.91.91 0 01-.924-.924.91.91 0 01.924-.924.91.91 0 01.924.924.935.935 0 01-.924.924zM8.929 84.827H5.112c-.04 0-.08-.04-.08-.08v-.804c0-1.084.883-1.968 1.968-1.968s1.969.884 1.969 1.968v.804c.08.04.04.08-.04.08zM157.5 40a5.99 5.99 0 01-6-6c0-3.321 2.679-6 6-6s6 2.679 6 6c.054 3.321-2.679 6-6 6zm0-11.84c-3.214 0-5.839 2.626-5.839 5.84s2.625 5.84 5.839 5.84c3.214 0 5.839-2.626 5.839-5.84s-2.625-5.84-5.839-5.84z"
          fill="#606060"
        />
        <path
          d="M157.501 32.875a1.212 1.212 0 01-1.232-1.232c0-.696.536-1.232 1.232-1.232.697 0 1.232.536 1.232 1.232 0 .643-.535 1.232-1.232 1.232zM160.073 37.107h-5.089c-.054 0-.108-.053-.108-.107V35.93a2.63 2.63 0 012.625-2.625 2.629 2.629 0 012.625 2.625V37c.108.054.054.107-.053.107zM217.5 39a5.99 5.99 0 01-6-6c0-3.321 2.679-6 6-6s6 2.679 6 6c.054 3.321-2.679 6-6 6zm0-11.84c-3.214 0-5.839 2.626-5.839 5.84s2.625 5.84 5.839 5.84c3.214 0 5.839-2.626 5.839-5.84s-2.625-5.84-5.839-5.84z"
          fill="#606060"
        />
        <path
          d="M217.501 31.875a1.212 1.212 0 01-1.232-1.232c0-.696.536-1.232 1.232-1.232.697 0 1.232.536 1.232 1.232 0 .643-.535 1.232-1.232 1.232zM220.073 36.107h-5.089c-.054 0-.108-.053-.108-.107V34.93a2.63 2.63 0 012.625-2.625 2.629 2.629 0 012.625 2.625V36c.108.054.054.107-.053.107z"
          fill="#606060"
        />
        <path stroke="#606060" strokeWidth={0.7} d="M8.85 51.999v19" />
        <path
          transform="rotate(16.556 -137.017 95.385) skewX(-.001)"
          stroke="#606060"
          strokeWidth={0.7}
          d="M0-.35h38.6"
        />
        <path stroke="#606060" strokeWidth={0.7} d="M8.85 51.999v19" />
        <path
          transform="rotate(-20.854 183.8 -327.15) skewX(.002)"
          stroke="#606060"
          strokeWidth={0.7}
          d="M0-.35h22.472"
        />
        <path
          stroke="#606060"
          strokeWidth={0.7}
          d="M127.5 64.648h29M166.517 29.65l41 2"
        />
        <path
          transform="rotate(-31.826 186.202 -287.296) skewX(.002)"
          stroke="#606060"
          strokeWidth={0.7}
          d="M0-.35h34.132"
        />
        <path stroke="#606060" strokeWidth={0.7} d="M13.5 78.647h22" />
        <path
          transform="rotate(-71.564 84.845 22.678) skewX(.001)"
          stroke="#606060"
          strokeWidth={0.7}
          d="M0-.35h12.649"
        />
      </g>
      <g opacity={0.5} filter="url(#prefix__filter0_dd_969:300)" fill="#fff">
        <rect
          x={64.5}
          y={18}
          width={58}
          height={60.997}
          rx={4}
          fillOpacity={0.1}
        />
        <path
          opacity={0.3}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.819 39.19c3.872 0 7.011-3.162 7.011-7.063s-3.14-7.064-7.011-7.064c-3.873 0-7.011 3.163-7.011 7.064 0 3.9 3.138 7.063 7.01 7.063zm-16.572 4.493h31.868v2.569H77.247v-2.569zm-3.187 4.495h38.242v2.568H74.06v-2.568zm42.704 10.915h-45.89v2.569h45.89v-2.569zm-35.693-6.42h24.22v2.568h-24.22v-2.568zm24.858 10.915h-24.22v2.568h24.22v-2.568zm-24.22 4.494h24.22v2.569h-24.22v-2.569z"
        />
      </g>
      <path
        d="M52.405 242.904s-20.7-51.253 9.584-81.86c39.059-39.467 89.524-6.471 108.46-7.441 18.937-.956 20.007-38.831 76.587-43.362 43.742-3.504 115.587-3.779 100.264 58.1-11.651 47.04 53.182 22.673 40.562 74.649l-335.457-.086z"
        fill="url(#prefix__paint0_linear_969:300)"
      />
      <path
        d="M137.013 169.327s18.638-5.616 19.393-5.48c.735.136-2.187 6.374-1.819 8.395.116.641-15.948 9.871-19.528 9.191-3.89-.739-18.87-13.68-20.457-16.478-1.781-3.129-4.916-10.96-2.826-13.894.949-1.34 3.252-3.128 4.839-2.759 6.328 1.516 13.18 12.805 20.398 21.025z"
        fill="#355389"
      />
      <path
        d="M138.039 190.779c-.116-.564-3.987-9.58-4.18-10.512-2.574-12.048-12.464-29.633-17.031-31.907-.736-.369-6.019-.408-6.019-.408l2.728 1.36c3.116 2.857 6.6 6.16 8.826 9.833 6.445 10.687 10.838 35.306 11.418 35.248.639-.019 4.529-2.37 4.258-3.614z"
        fill="url(#prefix__paint1_linear_969:300)"
      />
      <path
        d="M131.923 191.129c-9.057-5.286-10.47-17.761-14.302-24.464-4.684-8.201-11.593-9.094-23.495 5.771-14.593 18.246-7.161 54.932 12.966 66.688l48.288-.097c9.948-3.595 14.36-8.861 17.941-17.644 15.909-39.096-21.27-18.498-41.398-30.254z"
        fill="url(#prefix__paint2_linear_969:300)"
      />
      <path
        d="M153.348 231.643s-.329 5.965-.349 5.985c-.116.758-.619 2.623-.619 3.323 0 2.584 11.477-2.196 8.903-3.343-.233-.097-1.82-1.477-1.897-2.351-.116-1.36-.058-3.517-.058-3.517l-5.98-.097z"
        fill="#FFE5C2"
      />
      <path
        d="M161.128 237.492s-2.69.796-4.084.991c-1.374.194-3.193-1.477-4.18-1.011-.794.369-1.103 3.032-1.219 4.392a.751.751 0 00.716.816c3.174.175 15.25.719 18.366-.194 1.646-.467-4.083-3.148-9.599-4.994zM185.59 225.638s-2.69.797-4.083.991c-1.374.194-3.194-1.477-4.181-1.01-.793.369-1.103 3.031-1.219 4.391-.039.428.349.914.774.953 3.523.349 15.193.583 18.328-.311 1.645-.486-4.103-3.168-9.619-5.014z"
        fill="#112B46"
      />
      <path
        d="M174.289 220.801s3.232 4.566 3.522 5.557c.31 1.011 7.781 1.749 7.781-.719 0-.038-2.323-1.923-2.981-2.798-1.103-1.438-3.658-6.257-3.658-6.257l-4.664 4.217z"
        fill="#FFE5C2"
      />
      <path
        d="M104.77 121.506s-3.639-2.467-5.187 1.866c0 0-2.052-.622-2.942 1.496 0 0-3.522-.272-3.232 4.878 0 0-1.78.058-1.548 2.137.213 2.079 1.548 3.207 1.548 3.207s-.794.932.039 2.701c.716 1.515 2.09 1.457 2.09 1.457s.948 2.604 2.284 3.148c1.335.544 2.825-.194 2.825-.194s.736 1.36 2.052.932c1.316-.427 2.709-2.079 4.354-1.846 1.645.253 5.419-1.243 5.419-1.243s3.968-2.935 1.665-7.171c0 0 1.858-2.604-.716-4.683 0 0 .619-4.256-2.826-5.946-3.464-1.632-5.825-.739-5.825-.739z"
        fill="url(#prefix__paint3_linear_969:300)"
      />
      <path
        d="M162.54 186.076c-3.038-1.496-34.817 3.479-34.817 3.479l-3.813-6.102-12.405 2.818s-3.503 4.061-3.658 5.868c-.465 5.888 2.206 15.778 5.245 16.73 10.78 3.42 33.965-3.575 37.217-4.313 0 0-.116 5.266.871 16.05.387 4.255.89 8.938 1.374 13.038 2.845 0 5.67-.058 8.515-.427 0 0 4.49-45.644 1.471-47.141z"
        fill="url(#prefix__paint4_linear_969:300)"
      />
      <path
        d="M182.669 220.159c-5.439-13.777-17.225-40.845-21.502-41.603-5.613-.991-33.443 10.998-33.443 10.998l28.256-.583s.155.273 1.393 6.219c.968 4.624 11.593 20.947 17.651 29.982 2.768-1.263 5.535-2.429 7.645-5.013z"
        fill="url(#prefix__paint5_linear_969:300)"
      />
      <path
        d="M110.924 148.068s-7.025-.077-7.78.078c-.755.175.6 28.817 1.51 39.523.909 10.688 2.38 12.3 2.38 12.3s10.025.039 16.954-2.156c7.2-2.293 8.922-10.688 8.922-10.688s-6.522-19.217-9.154-25.183c-1.684-3.808-5.013-8.103-8.4-11.6-1.896-1.963-4.432-2.274-4.432-2.274z"
        fill="#fff"
      />
      <path
        d="M108.951 142.842l1.412 3.867s2.458 5.48 1.162 6.918c-1.297 1.457-8.09-5.558-8.09-5.558l-1.684-10.221 7.2 4.994z"
        fill="#FFE5C2"
      />
      <path
        d="M102.273 141.171c2.032 3.167 5.535 4.605 7.858 4.955l-1.2-3.284-7.219-4.975.561 3.304z"
        fill="#FED2A4"
      />
      <path
        d="M102.97 126.462c2.883-1.769 6.677-.428 7.877 2.701a38.072 38.072 0 011.548 5.013c1.103 4.645-.29 9.697-2.4 10.163-2.109.467-8.593-1.263-10.257-7.811-1.026-4.042.193-8.201 3.232-10.066z"
        fill="#FFE5C2"
      />
      <path
        d="M101.403 136.721c.232 1.03-.271 2.001-1.103 2.196-.832.194-1.703-.506-1.916-1.516-.232-1.03.27-2.002 1.103-2.196.832-.194 1.703.486 1.916 1.516z"
        fill="#FFE5C2"
      />
      <path
        d="M103.415 148.049s-8.167-.331-10.025 1.399c-1.18 1.088 1.22 12.067 1.22 12.067s6.657 12.805 7.625 20.286c1.606 12.436 3.077 20.073 3.716 20.5 3.677 1.321 15.812-1.185 16.315-2.623 5.554-15.895-18.851-51.629-18.851-51.629z"
        fill="url(#prefix__paint6_linear_969:300)"
      />
      <path
        d="M142.896 174.397l5.419-22.987a1.306 1.306 0 011.278-1.011h9.851c.871 0 1.49.817 1.277 1.652l-5.98 22.618a1.303 1.303 0 01-1.22.972l-9.289.369a1.313 1.313 0 01-1.336-1.613z"
        fill="url(#prefix__paint7_linear_969:300)"
      />
      <path
        d="M151.896 173.796c-.425.077-1.103.233-1.587.408-.522.194-1.045.194-1.509.291-.91.195-1.568.331-1.549.292.02-.059-.193-.136.291-.311.561-.214 1.877-.408 2.69-.797.464-.214 1.451-.602 1.451-.602s1.394-.875 1.355-.972c-.116-.525-1.238-.175-1.819.194-.503.059-1.529.506-1.529.506s-2.284.447-2.342.136c-.019-.136 1.336-.564 2.613-1.205 1.181-.583 2.284-1.36 2.206-1.613-.135-.408-1.509.097-1.509.097s-.949.408-1.278.525c-.387.136-1.548.447-1.548.447s-4.142.913-4.161.602c-.039-.311.194-1.127.426-1.263.232-.136.813-.175 1.045-.33.232-.136.426-.525.271-.816-.155-.292-1.568-.039-1.568-.039s-1.142.213-1.761.738c-2.148 1.807-1.761 2.079-2.032 2.293-.329.272-5.98.894-5.98.894s-16.625 1.943-17.535 2.021l.581 5.558c.987.097 20.186-3.265 22.16-3.654 0 0 2.516.37 4.103.564 1.587.194 3.193-.33 3.638-.35.058 0 1.587-.136 3.406-.797 1.587-.563-.174-.738-.174-.738s-3.096.544-3.096.291c0-.194 1.722-.388 3.464-.991.445-.155.89-.311 1.297-.466 1.083-.389 1.161-1.127-.02-.913z"
        fill="#FFE5C2"
      />
      <path
        d="M114.04 172.552s19.393-.136 20.147 0c.736.136.949 5.13 1.316 7.151.117.641-20.456 2.953-24.018 2.273-3.89-.738-13.702-11.561-15.966-15.215-1.897-3.07-5.98-10.862-4.955-14.262.62-2.06 3.793-4.392 5.516-3.848 6.909 2.157 17.96 23.901 17.96 23.901z"
        fill="#86B1F2"
      />
      <path
        d="M157.993 163.38a.643.643 0 01.871.466c.077.408.077.894-.233 1.205-.619.622-4.722 1.069-4.722.466 0-.486 2.806-1.632 4.084-2.137z"
        fill="#FFE5C2"
      />
      <path
        d="M158.902 165.168s.464 1.515.116 1.554c-.348.02-4.761.642-4.993.136-.213-.524 4.296-1.962 4.877-1.69z"
        fill="#FFE5C2"
      />
      <path
        d="M159.096 166.898s.174.68-.174 1.088c-.348.427-4.471.427-4.664-.214-.213-.661 4.664-1.38 4.838-.874z"
        fill="#FFE5C2"
      />
      <path
        d="M158.148 168.452s.077.525-.078.777c-.174.253-3.232.408-3.638-.175-.407-.621 3.251-1.224 3.716-.602z"
        fill="#FFE5C2"
      />
      <path
        opacity={0.1}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.451 133.262h11.213c3.154 0 5.728-2.565 5.709-5.732v-6.801c0-3.168-2.555-5.733-5.709-5.733h-24.038c-3.154 0-5.709 2.565-5.709 5.733v6.801c0 3.167 2.555 5.732 5.709 5.732h7.169l9.649 15.351-3.993-15.351z"
        fill="#fff"
      />
      <path
        d="M276.788 102.143l-.889-4.078s-2.932 1.85-4.652 4.513c-1.721 2.662-2.535 9.743-3.007 10.008-.492.264-5.901-8.063-6.846-9.385-1.173-1.624-1.608-3.494-2.345-4.91-.738-1.416-3.31-1.907-4.293-1.718-.984.17 1.343 4.324 1.343 4.324s5.862 10.178 7.829 15.748c1.948 5.571 8.075 8.233 9.039 7.44.984-.774 3.821-21.942 3.821-21.942z"
        fill="#FFE5C2"
      />
      <path
        d="M258.973 99.103s-.245-1.076-.907-1.284c-.662-.227-1.797-.019-1.873 0-.075.019-1.342-.66-1.342-.66l1.04 1.944s2.042 2.417 2.723 1.511c.7-.907.359-1.51.359-1.51zM254.492 97.233s-.738 3.758-.549 4.551c.189.793.662.359.757.189.359-.699.624-3.116.624-3.116l-.832-1.624z"
        fill="#FFE5C2"
      />
      <path
        d="M256.08 100.368s-.36 2.323-.719 2.606c-.227.17-.53.076-.643-.396-.095-.472.473-3.343.473-3.343l.889 1.133z"
        fill="#FFE5C2"
      />
      <path
        d="M258.236 106.978l1.229-.604-5.219-10.688a1.356 1.356 0 00-.624 1.831l4.614 9.461z"
        fill="url(#prefix__paint8_linear_969:300)"
      />
      <path
        d="M271.342 85.413c-.738-3.343-1.457-6.704.491-10.386 1.57-2.964 4.293-4.683 7.432-5.06 8.908-1.058 6.998 7.25 19.441 9.044 3.858.567 7.451 1.096 10.685 3.022 5.825 3.474 8.038 9.026 4.161 18.09-9.475 22.187-32.717 3.361-35.573-1.02-2.137-3.248-6.146-11.538-6.637-13.69z"
        fill="url(#prefix__paint9_linear_969:300)"
      />
      <path
        d="M306.971 225.58s.642 6.421 2.269 7.61c1.626 1.19 3.801 5.552 2.723 7.912-.34.737-1.021 1.171-1.796 1.398-2.289.698-4.728-.472-5.693-2.663-.548-1.227-1.021-2.851-1.116-4.91-.245-5.23-1.872-7.874-1.872-7.874l5.485-1.473zM281.913 228.205s-.51 6.496-.094 8.592c.227 1.133 1.475 3.456.889 4.513-.568 1.058-1.23 1.322-1.23 1.322l-14.788.246c-.813.019-.265-.51.378-.982 1.059-.775 1.929-1.303 5.314-2.927.227-.114 1.021-.623 1.324-.793.737-.454 1.475-1.152 2.118-1.87 1.059-1.171 1.154-6.892 1.154-6.892l4.935-1.209z"
        fill="#FFE5C2"
      />
      <path
        d="M270.358 141.986s-1.853 10.536-.643 17.428c1.23 6.912 7.092 70.905 7.092 70.905l6.525.359s1.361-26.738 1.115-30.458c-.245-3.72-2.174-9.932-2.174-9.932l.643-21.583-8.227-28.362-4.331 1.643z"
        fill="url(#prefix__paint10_linear_969:300)"
      />
      <path
        d="M289.516 139.777s5.144 7.704 6.354 14.067c1.229 6.383-.889 25.964 1.456 34.311 1.267 4.513 6.09 11.499 6.827 17.353.738 5.835 3.612 21.149 3.612 21.149l-6.524 1.284-15.148-38.219-11.877-34.537-3.044-17.788 18.344 2.38z"
        fill="url(#prefix__paint11_linear_969:300)"
      />
      <path
        d="M285.374 95.95s4.501.51 7.186 3.437c2.686 2.927-4.349 26.398-4.841 30.118-.492 3.72 4.009 12.387 4.009 12.387s-16.793 3.097-21.37.095c0 0-.491-26.739 0-29.382.492-2.663 4.558-13.464 5.541-14.521.984-1.058 9.475-2.134 9.475-2.134z"
        fill="url(#prefix__paint12_linear_969:300)"
      />
      <path
        d="M291.728 101.595c-.435-1.303-1.91-1.945-3.177-1.397-1.74.755-4.142 2.172-5.295 4.607-1.948 4.079-8.794 18.052-10.931 18.581-2.118.529-10.761-14.521-10.761-14.521s.246-2.662-.491-4.154c-.738-1.511-2.931-3.097-3.253-2.568-.321.529 1.229 1.85 1.229 2.134 0 .264-.643.434-1.55-.095-.889-.528-1.381-1.605-1.381-1.605s-1.475 2.663.076 5.061a312.811 312.811 0 002.193 3.361s10.023 22.225 13.692 22.489c3.404.246 23.072-21.545 19.649-31.893z"
        fill="#FFE5C2"
      />
      <path
        d="M283.104 86.905s.038.208.095.548c.302 1.624 1.475 7.024 2.25 8.516.946 1.794-3.271 7.516-7.319 6.817-1.21-.208 1.022-5.268.908-5.797-.208-1.02-.491-2.417-.775-3.852-.151-.812-.322-1.624-.473-2.399-.435-2.19-.794-3.984-.794-3.984l6.108.151z"
        fill="#FFE5C2"
      />
      <path
        d="M283.199 87.434c-.057-.34-.094-.548-.094-.548l-6.071-.15s.359 1.793.794 3.984c.152.774.322 1.586.473 2.38.946-.133 1.91-.586 2.893-1.455a7.531 7.531 0 002.194-3.267c-.075-.359-.132-.68-.189-.944z"
        fill="#FED2A4"
      />
      <path
        d="M279.455 72.478s2.477.34 4.274 2.663c1.796 2.322 3.725 9.48-.738 13.37-4.463 3.89-8.869-.51-9.531-5.194-.643-4.664-.511-11.009 5.995-10.839z"
        fill="#FFE5C2"
      />
      <path
        d="M272.042 81.713c.529 1.038 1.418 1.605 2.004 1.284.568-.321.606-1.436.076-2.455-.53-1.039-1.418-1.605-2.005-1.284-.586.32-.605 1.435-.075 2.455z"
        fill="#FFE5C2"
      />
      <path
        d="M278.698 72.365s3.82 6.817 6.562 7.119c2.743.32 3.102 3.928 3.102 3.928s-.984-8.328-3.688-9.952c-2.723-1.586-5.976-1.095-5.976-1.095z"
        fill="url(#prefix__paint13_linear_969:300)"
      />
      <path
        d="M284.353 81.108c-.227 1.209.227 2.323 1.002 2.474.775.151 1.589-.699 1.815-1.907.227-1.209-.226-2.323-1.002-2.474-.775-.151-1.588.699-1.815 1.907z"
        fill="#FFE5C2"
      />
      <path
        d="M304.001 238.686c3.555.642 5.919-1.417 7.149-2.984.907 1.756 1.456 3.947.794 5.382-.586 1.265-2.175 1.624-3.518 1.662a3.83 3.83 0 01-3.46-1.926 10.82 10.82 0 01-.965-2.134zM273.724 238.175c3.915 1 6.808.321 8.416-.321.416 1.17.964 2.662.681 3.512-.36 1.038-.87 1.284-1.324 1.284-3.574.094-13.087.227-15.81.264a.446.446 0 01-.34-.736c.662-.812 8.075-3.833 8.377-4.003z"
        fill="#112B46"
      />
      <path
        opacity={0.1}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M266.898 57.695h-17.683c-3.896 0-7.054-3.154-7.054-7.044v-8.365c0-3.89 3.158-7.044 7.054-7.044h29.729c3.895 0 7.054 3.154 7.054 7.044v8.365c0 3.89-3.159 7.044-7.054 7.044h-5.886l3.238 9.685-9.398-9.685z"
        fill="#fff"
      />
      <path
        opacity={0.3}
        d="M273.137 47.307c0 1.133.984 2.04 2.194 2.04 1.211 0 2.194-.926 2.194-2.04 0-1.133-.983-2.04-2.194-2.04-1.21 0-2.194.926-2.194 2.04zM264.099 49.346c-1.212 0-2.194-.913-2.194-2.039 0-1.126.982-2.04 2.194-2.04 1.211 0 2.193.914 2.193 2.04s-.982 2.04-2.193 2.04zM250.69 47.307c0 1.133.984 2.04 2.194 2.04s2.194-.926 2.194-2.04c0-1.133-.984-2.04-2.194-2.04s-2.194.926-2.194 2.04z"
        fill="#fff"
      />
      <path
        d="M257.972 146.139c-2.742-.789-2.818-.301-3.177-.413-.416-.132-3.745-4.601-3.745-4.601s-9.909-13.106-10.401-13.838l-3.12-16.975-9.38-.075s3.914 18.327 4.311 19.228c.379.901 16.075 16.918 17.399 18.382 0 0 .851 2.366 1.456 3.831.586 1.464 1.853 2.535 2.099 2.91.038.057.908 1.277 2.421 2.46 1.305 1.014.605-.563.605-.563s-2.023-2.347-1.778-2.479c.189-.112 1.192 1.239 2.629 2.385.359.3.738.582 1.059.845.889.695 1.626.319.832-.545-.283-.3-.756-.807-1.153-1.126-.435-.338-.7-.789-1.003-1.108-.624-.676-1.078-1.146-1.04-1.164.057-.019.038-.244.435.056.473.357 1.305 1.371 2.061 1.84.435.263 1.286.883 1.286.883s1.494.676 1.57.6c.435-.413-.435-1.164-1.059-1.445-.284-.395-1.21-1.014-1.21-1.014s-1.532-1.69-1.267-1.934c.113-.094 1.191.826 2.42 1.539 1.135.676 2.421 1.165 2.61.958.322-.357-.832-1.239-.832-1.239s-.851-.582-1.116-.789c-.302-.244-1.172-1.07-1.172-1.07s-2.856-3.023-2.591-3.23c.265-.206 1.153-.488 1.418-.357.246.113.568.583.813.714.246.113.7.056.927-.244.208-.301-.719-1.352-.719-1.352s-.794-.845-1.588-1.07zM242.389 231.273l-.34 5.859c-.114.751-.605 2.573-.605 3.249 0 2.535 11.384-2.16 8.831-3.286-.227-.094-1.437-.864-1.513-1.728-.113-1.333-.416-4.018-.416-4.018l-5.957-.076z"
        fill="#FFE5C2"
      />
      <path
        d="M250.256 237.094s-2.667.77-4.047.976c-1.362.188-3.177-1.446-4.142-.995-1.059.488-.964 5.277-.964 5.277s14.259.826 18.287-.451c1.608-.545-3.688-3.005-9.134-4.807z"
        fill="#112B46"
      />
      <path
        d="M217.332 231.837l-.341 5.859c-.113.751-.605 2.572-.605 3.248 0 2.535 11.385-2.159 8.832-3.286-.227-.094-1.797-1.446-1.873-2.31-.113-1.333-.056-3.455-.056-3.455l-5.957-.056z"
        fill="#FFE5C2"
      />
      <path
        d="M225.18 237.639s-2.667.77-4.047.976c-1.362.188-3.177-1.446-4.142-.995-1.059.488-1.116 4.863-1.116 4.863s15.243 1.09 18.817.076c1.645-.47-4.047-3.099-9.512-4.92z"
        fill="#112B46"
      />
      <path
        d="M235.353 147.303l4.804 2.065 10.117 47.975-.416 35.958-8.113.188s-3.763-31.452-3.763-33.893c0-2.441-17.474-42.661-17.474-42.661l14.845-9.632z"
        fill="url(#prefix__paint14_linear_969:300)"
      />
      <path
        d="M231.647 149.951s-3.536 36.952-3.536 43.186c0 6.234-2.874 41.103-2.874 41.103l-8.132-.038s-.587-10.627-1.457-26.268c-.132-2.441.171-7.399 0-9.933-1.267-18.233-3.763-35.395-2.572-42.211.19-1.089.549-3.962 1.249-4.675 5.333-5.464 17.322-1.164 17.322-1.164z"
        fill="url(#prefix__paint15_linear_969:300)"
      />
      <path
        d="M241.557 120.79l-9.834-.657s-.492-8.731-.492-12.9c0-5.051-1.551-12.505-.946-12.355 1.702.394 4.634 1.953 5.995 3.83 4.728 6.535 5.277 22.082 5.277 22.082z"
        fill="url(#prefix__paint16_linear_969:300)"
      />
      <path
        d="M218.258 94.671s-5.616.77-7.092 1.86c-1.664 1.22-3.517 3.923-3.517 3.923s6.884 32.334 6.903 37.761c.018 4.206-1.494 14.533-1.494 14.533s20.821 1.296 28.688-1.784c0 0-2.496-12.28-2.496-15.509 0-3.23-2.969-32.578-3.65-34.625-.624-1.859-1.74-5.201-5.541-6.027-3.782-.808-11.801-.132-11.801-.132z"
        fill="url(#prefix__paint17_linear_969:300)"
      />
      <path
        d="M219.789 85.979l-1.38 9.032s5.068 10.741 8.094 9.821c2.534-.789.983-10.29.983-10.29s-1.38-5.108-.964-7.662c.397-2.572-6.733-.901-6.733-.901z"
        fill="#FFE5C2"
      />
      <path
        d="M226.503 86.861c.416-2.554-6.713-.883-6.713-.883l-.114.752c.208.507.473.92.776 1.258 1.588 1.69 4.084 2.704 6.24 2.779-.227-1.371-.359-2.854-.189-3.906z"
        fill="#FED2A4"
      />
      <path
        d="M218.617 73.21c-.208-.075-1.608 10.29 1.059 13.125 2.685 2.855 7.905 3.775 9.796 1.54 2.08-2.478 1.967-13.614-2.194-16.543-4.179-2.93-8.661 1.878-8.661 1.878z"
        fill="#FFE5C2"
      />
      <path
        d="M225.671 65.793c-3.234.657-5.314 2.59-6.089 3.417-1.456.17-3.461.977-5.503 4.319-2.534 4.131-.662 8.694 2.288 10.947 2.95 2.235 2.912 4.845 2.912 4.845l.454-1.352c.341-1.014.492-2.084.359-3.155-.075-.638-.208-1.333-.472-1.896-.7-1.502 1.513-2.498 2.137-3.737.472-.958.51-3.155 1.267-4.75l.907-1.221s2.081-1.596 4.123-1.146c2.062.47 1.759-7.098-2.383-6.271z"
        fill="url(#prefix__paint18_linear_969:300)"
      />
      <path
        d="M221.643 81.021c.019 1.258-.567 2.291-1.305 2.31-.737.019-1.361-.995-1.38-2.272-.019-1.258.567-2.291 1.305-2.31.737 0 1.342 1.014 1.38 2.272z"
        fill="#FFE5C2"
      />
      <path
        d="M232.554 127.55l.133.77a.756.756 0 00.851.601l16.093-2.76a.753.753 0 00.605-.845l-.132-.77a.758.758 0 00-.851-.601l-16.093 2.76a.754.754 0 00-.606.845z"
        fill="url(#prefix__paint19_linear_969:300)"
      />
      <path
        d="M209.748 98.84c1.891-.056 3.555 12.299 2.364 13.519-1.192 1.221-1.419 16.449-1.419 16.449l17.815-.432c.34-.225 4.085-2.723 4.614-3.267.568-.563 3.272-.282 3.99.507.719.788-1.607.15-2.231.563-.643.432.473 1.502.473 1.502s4.52.658 5.389-.281c.889-.92 3.234-2.498 3.556-1.578.17.47-1.04 1.934-1.721 2.535.851-.638 1.872-1.314 2.231-1.183.643.207.379 1.014.019 1.258-.34.244-3.933 2.948-4.482 3.136-.548.188-8.869.394-10.325.714 0 0-23.488 5.314-24.812 4.976-1.173-.282-2.345-7.755-2.345-13.463-.019-5.709.983-24.786 6.884-24.955z"
        fill="#FFE5C2"
      />
      <path
        d="M212.206 96.023s-3.291.752-6.676 4.544c-3.082 3.437-5.03 22.72-5.03 22.72l10.647 1.709.851-9.332 2.856-4.262-2.648-15.379z"
        fill="url(#prefix__paint20_linear_969:300)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_969:300"
          x1={217.5}
          y1={108.996}
          x2={217.5}
          y2={242.99}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_969:300"
          x1={109.535}
          y1={155.034}
          x2={128.01}
          y2={196.786}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear_969:300"
          x1={131.835}
          y1={160.803}
          x2={131.835}
          y2={239.124}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear_969:300"
          x1={103.384}
          y1={120.861}
          x2={103.384}
          y2={143.12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear_969:300"
          x1={107.833}
          y1={208.573}
          x2={163.632}
          y2={208.573}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear_969:300"
          x1={127.762}
          y1={201.857}
          x2={182.716}
          y2={201.857}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear_969:300"
          x1={91.66}
          y1={156.357}
          x2={114.354}
          y2={204.387}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear_969:300"
          x1={151.813}
          y1={150.399}
          x2={151.813}
          y2={176.011}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear_969:300"
          x1={256.471}
          y1={95.686}
          x2={256.471}
          y2={106.978}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear_969:300"
          x1={292.964}
          y1={69.974}
          x2={292.964}
          y2={110.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear_969:300"
          x1={284.467}
          y1={185.553}
          x2={269.292}
          y2={185.553}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear_969:300"
          x1={307.74}
          y1={182.712}
          x2={271.14}
          y2={182.712}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear_969:300"
          x1={294.246}
          y1={103.17}
          x2={273.103}
          y2={142.787}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="prefix__paint13_linear_969:300"
          x1={283.515}
          y1={72.32}
          x2={283.515}
          y2={83.363}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint14_linear_969:300"
          x1={250.254}
          y1={190.437}
          x2={220.482}
          y2={190.437}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint15_linear_969:300"
          x1={231.634}
          y1={191.332}
          x2={212.747}
          y2={191.332}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint16_linear_969:300"
          x1={242.09}
          y1={98.828}
          x2={229.902}
          y2={119.503}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="prefix__paint17_linear_969:300"
          x1={243.34}
          y1={103.344}
          x2={219.892}
          y2={155.893}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="prefix__paint18_linear_969:300"
          x1={221.069}
          y1={65.787}
          x2={221.069}
          y2={89.219}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint19_linear_969:300"
          x1={241.395}
          y1={123.938}
          x2={241.395}
          y2={128.929}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint20_linear_969:300"
          x1={215.525}
          y1={100.441}
          x2={202.72}
          y2={124.88}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <filter
          id="prefix__filter0_dd_969:300"
          x={50.5}
          y={0}
          width={98}
          height={100.997}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={6} dy={2} />
          <feGaussianBlur stdDeviation={10} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_969:300"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="effect1_dropShadow_969:300"
            result="effect2_dropShadow_969:300"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_969:300"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg
      width={390}
      height={243}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity={0.4}>
        <path
          d="M8.874 50.748A8.36 8.36 0 01.5 42.374 8.36 8.36 0 018.874 34a8.36 8.36 0 018.374 8.374c.075 4.636-3.739 8.374-8.374 8.374zm0-16.524c-4.486 0-8.15 3.664-8.15 8.15 0 4.486 3.664 8.15 8.15 8.15 4.486 0 8.15-3.664 8.15-8.15 0-4.486-3.664-8.15-8.15-8.15z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
        <path
          d="M8.875 40.804c-.972 0-1.72-.747-1.72-1.72 0-.971.748-1.719 1.72-1.719s1.72.748 1.72 1.72c0 .897-.748 1.72-1.72 1.72zM12.464 46.71H5.361a.16.16 0 01-.15-.149v-1.495a3.67 3.67 0 013.665-3.664 3.67 3.67 0 013.663 3.664v1.495c.15.075.075.15-.075.15zM169.874 70.746a8.36 8.36 0 01-8.374-8.374 8.36 8.36 0 018.374-8.374 8.36 8.36 0 018.374 8.374c.075 4.635-3.739 8.374-8.374 8.374zm0-16.524c-4.486 0-8.15 3.664-8.15 8.15 0 4.486 3.664 8.15 8.15 8.15 4.486 0 8.15-3.664 8.15-8.15 0-4.486-3.664-8.15-8.15-8.15z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
        <path
          d="M169.875 60.803a1.691 1.691 0 01-1.719-1.72c0-.972.747-1.72 1.719-1.72.972 0 1.72.748 1.72 1.72 0 .897-.748 1.72-1.72 1.72zM173.464 66.709h-7.103c-.074 0-.149-.075-.149-.15v-1.495a3.67 3.67 0 013.663-3.664 3.67 3.67 0 013.664 3.664v1.495c.15.075.075.15-.075.15zM30.874 113.745a8.36 8.36 0 01-8.374-8.374 8.36 8.36 0 018.374-8.374 8.36 8.36 0 018.374 8.374c.075 4.636-3.738 8.374-8.374 8.374zm0-16.524c-4.486 0-8.15 3.664-8.15 8.15 0 4.486 3.664 8.15 8.15 8.15 4.486 0 8.15-3.664 8.15-8.15 0-4.486-3.664-8.15-8.15-8.15z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
        <path
          d="M30.875 103.801c-.972 0-1.72-.748-1.72-1.72s.748-1.72 1.72-1.72 1.72.748 1.72 1.72c0 .897-.748 1.72-1.72 1.72zM34.464 109.707h-7.103c-.074 0-.15-.075-.15-.15v-1.495a3.67 3.67 0 013.665-3.664 3.67 3.67 0 013.663 3.664v1.495c.15.075.075.15-.075.15zM42.5 81.998a5.99 5.99 0 01-6-6c0-3.321 2.679-6 6-6s6 2.679 6 6c.054 3.322-2.679 6-6 6zm0-11.84c-3.214 0-5.84 2.626-5.84 5.84s2.626 5.84 5.84 5.84 5.84-2.626 5.84-5.84-2.626-5.84-5.84-5.84z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
        <path
          d="M42.5 74.874a1.212 1.212 0 01-1.231-1.233c0-.696.535-1.232 1.232-1.232.696 0 1.232.536 1.232 1.232 0 .643-.536 1.233-1.232 1.233zM45.072 79.105h-5.089a.115.115 0 01-.107-.107v-1.071a2.63 2.63 0 012.625-2.625 2.63 2.63 0 012.625 2.625v1.071c.107.054.053.107-.054.107zM7 86.997a4.493 4.493 0 01-4.5-4.5c0-2.491 2.009-4.5 4.5-4.5s4.5 2.009 4.5 4.5c.04 2.491-2.009 4.5-4.5 4.5zm0-8.88a4.39 4.39 0 00-4.38 4.38A4.39 4.39 0 007 86.877a4.39 4.39 0 004.38-4.38A4.39 4.39 0 007 78.117z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
        <path
          d="M7 81.653a.91.91 0 01-.924-.924.91.91 0 01.924-.924.91.91 0 01.924.924.935.935 0 01-.924.924zM8.929 84.827H5.112c-.04 0-.08-.04-.08-.08v-.804c0-1.084.883-1.968 1.968-1.968s1.969.884 1.969 1.968v.804c.08.04.04.08-.04.08zM157.5 40a5.99 5.99 0 01-6-6c0-3.321 2.679-6 6-6s6 2.679 6 6c.054 3.321-2.679 6-6 6zm0-11.84c-3.214 0-5.839 2.626-5.839 5.84s2.625 5.84 5.839 5.84c3.214 0 5.839-2.626 5.839-5.84s-2.625-5.84-5.839-5.84z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
        <path
          d="M157.501 32.875a1.212 1.212 0 01-1.232-1.232c0-.696.536-1.232 1.232-1.232.697 0 1.232.536 1.232 1.232 0 .643-.535 1.232-1.232 1.232zM160.073 37.107h-5.089c-.054 0-.108-.053-.108-.107V35.93a2.63 2.63 0 012.625-2.625 2.629 2.629 0 012.625 2.625V37c.108.054.054.107-.053.107zM217.5 39a5.99 5.99 0 01-6-6c0-3.321 2.679-6 6-6s6 2.679 6 6c.054 3.321-2.679 6-6 6zm0-11.84c-3.214 0-5.839 2.626-5.839 5.84s2.625 5.84 5.839 5.84c3.214 0 5.839-2.626 5.839-5.84s-2.625-5.84-5.839-5.84z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
        <path
          d="M217.501 31.875a1.212 1.212 0 01-1.232-1.232c0-.696.536-1.232 1.232-1.232.697 0 1.232.536 1.232 1.232 0 .643-.535 1.232-1.232 1.232zM220.073 36.107h-5.089c-.054 0-.108-.053-.108-.107V34.93a2.63 2.63 0 012.625-2.625 2.629 2.629 0 012.625 2.625V36c.108.054.054.107-.053.107z"
          fill="#8493A8"
          fillOpacity={0.8}
        />
        <path stroke="#8493A8" strokeWidth={0.7} d="M8.85 51.999v19" />
        <path
          transform="rotate(16.556 -137.017 95.385) skewX(-.001)"
          stroke="#8493A8"
          strokeWidth={0.7}
          d="M0-.35h38.6"
        />
        <path stroke="#8493A8" strokeWidth={0.7} d="M8.85 51.999v19" />
        <path
          transform="rotate(-20.854 183.8 -327.15) skewX(.002)"
          stroke="#8493A8"
          strokeWidth={0.7}
          d="M0-.35h22.472"
        />
        <path
          stroke="#8493A8"
          strokeWidth={0.7}
          d="M127.5 64.648h29M166.517 29.65l41 2"
        />
        <path
          transform="rotate(-31.826 186.202 -287.296) skewX(.002)"
          stroke="#8493A8"
          strokeWidth={0.7}
          d="M0-.35h34.132"
        />
        <path stroke="#8493A8" strokeWidth={0.7} d="M13.5 78.647h22" />
        <path
          transform="rotate(-71.564 84.845 22.678) skewX(.001)"
          stroke="#8493A8"
          strokeWidth={0.7}
          d="M0-.35h12.649"
        />
      </g>
      <g opacity={0.5} filter="url(#prefix__filter0_dd_969:150)">
        <rect x={64.5} y={18} width={58} height={60.997} rx={4} fill="#fff" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.819 39.19c3.872 0 7.011-3.162 7.011-7.063s-3.14-7.064-7.011-7.064c-3.873 0-7.011 3.163-7.011 7.064 0 3.9 3.138 7.063 7.01 7.063zm-16.572 4.493h31.868v2.569H77.247v-2.569zm-3.187 4.495h38.242v2.568H74.06v-2.568zm42.704 10.915h-45.89v2.569h45.89v-2.569zm-35.693-6.42h24.22v2.568h-24.22v-2.568zm24.858 10.915h-24.22v2.568h24.22v-2.568zm-24.22 4.494h24.22v2.569h-24.22v-2.569z"
          fill="#F1F4F9"
        />
      </g>
      <path
        d="M52.405 242.904s-20.7-51.253 9.584-81.86c39.059-39.467 89.524-6.471 108.46-7.441 18.937-.956 20.007-38.831 76.587-43.362 43.742-3.504 115.587-3.779 100.264 58.1-11.651 47.04 53.182 22.673 40.562 74.649l-335.457-.086z"
        fill="url(#prefix__paint0_linear_969:150)"
      />
      <path
        d="M137.013 169.327s18.638-5.616 19.393-5.48c.735.136-2.187 6.374-1.819 8.395.116.641-15.948 9.871-19.528 9.191-3.89-.739-18.87-13.68-20.457-16.478-1.781-3.129-4.916-10.96-2.826-13.894.949-1.34 3.252-3.128 4.839-2.759 6.328 1.516 13.18 12.805 20.398 21.025z"
        fill="#355389"
      />
      <path
        d="M138.039 190.779c-.116-.564-3.987-9.58-4.18-10.512-2.574-12.048-12.464-29.633-17.031-31.907-.736-.369-6.019-.408-6.019-.408l2.728 1.36c3.116 2.857 6.6 6.16 8.826 9.833 6.445 10.687 10.838 35.306 11.418 35.248.639-.019 4.529-2.37 4.258-3.614z"
        fill="url(#prefix__paint1_linear_969:150)"
      />
      <path
        d="M131.923 191.129c-9.057-5.286-10.47-17.761-14.302-24.464-4.684-8.201-11.593-9.094-23.495 5.771-14.593 18.246-7.161 54.932 12.966 66.688l48.288-.097c9.948-3.595 14.36-8.861 17.941-17.644 15.909-39.096-21.27-18.498-41.398-30.254z"
        fill="url(#prefix__paint2_linear_969:150)"
      />
      <path
        d="M153.348 231.643s-.329 5.965-.349 5.985c-.116.758-.619 2.623-.619 3.323 0 2.584 11.477-2.196 8.903-3.343-.233-.097-1.82-1.477-1.897-2.351-.116-1.36-.058-3.517-.058-3.517l-5.98-.097z"
        fill="#FFE5C2"
      />
      <path
        d="M161.128 237.492s-2.69.796-4.084.991c-1.374.194-3.193-1.477-4.18-1.011-.794.369-1.103 3.032-1.219 4.392a.751.751 0 00.716.816c3.174.175 15.25.719 18.366-.194 1.646-.467-4.083-3.148-9.599-4.994zM185.59 225.638s-2.69.797-4.083.991c-1.374.194-3.194-1.477-4.181-1.01-.793.369-1.103 3.031-1.219 4.391-.039.428.349.914.774.953 3.523.349 15.193.583 18.328-.311 1.645-.486-4.103-3.168-9.619-5.014z"
        fill="#112B46"
      />
      <path
        d="M174.289 220.801s3.232 4.566 3.522 5.557c.31 1.011 7.781 1.749 7.781-.719 0-.038-2.323-1.923-2.981-2.798-1.103-1.438-3.658-6.257-3.658-6.257l-4.664 4.217z"
        fill="#FFE5C2"
      />
      <path
        d="M104.77 121.506s-3.639-2.467-5.187 1.866c0 0-2.052-.622-2.942 1.496 0 0-3.522-.272-3.232 4.878 0 0-1.78.058-1.548 2.137.213 2.079 1.548 3.207 1.548 3.207s-.794.932.039 2.701c.716 1.515 2.09 1.457 2.09 1.457s.948 2.604 2.284 3.148c1.335.544 2.825-.194 2.825-.194s.736 1.36 2.052.932c1.316-.427 2.709-2.079 4.354-1.846 1.645.253 5.419-1.243 5.419-1.243s3.968-2.935 1.665-7.171c0 0 1.858-2.604-.716-4.683 0 0 .619-4.256-2.826-5.946-3.464-1.632-5.825-.739-5.825-.739z"
        fill="url(#prefix__paint3_linear_969:150)"
      />
      <path
        d="M162.54 186.076c-3.038-1.496-34.817 3.479-34.817 3.479l-3.813-6.102-12.405 2.818s-3.503 4.061-3.658 5.868c-.465 5.888 2.206 15.778 5.245 16.73 10.78 3.42 33.965-3.575 37.217-4.313 0 0-.116 5.266.871 16.05.387 4.255.89 8.938 1.374 13.038 2.845 0 5.67-.058 8.515-.427 0 0 4.49-45.644 1.471-47.141z"
        fill="url(#prefix__paint4_linear_969:150)"
      />
      <path
        d="M182.669 220.159c-5.439-13.777-17.225-40.845-21.502-41.603-5.613-.991-33.443 10.998-33.443 10.998l28.256-.583s.155.273 1.393 6.219c.968 4.624 11.593 20.947 17.651 29.982 2.768-1.263 5.535-2.429 7.645-5.013z"
        fill="url(#prefix__paint5_linear_969:150)"
      />
      <path
        d="M110.924 148.068s-7.025-.077-7.78.078c-.755.175.6 28.817 1.51 39.523.909 10.688 2.38 12.3 2.38 12.3s10.025.039 16.954-2.156c7.2-2.293 8.922-10.688 8.922-10.688s-6.522-19.217-9.154-25.183c-1.684-3.808-5.013-8.103-8.4-11.6-1.896-1.963-4.432-2.274-4.432-2.274z"
        fill="#fff"
      />
      <path
        d="M108.951 142.842l1.412 3.867s2.458 5.48 1.162 6.918c-1.297 1.457-8.09-5.558-8.09-5.558l-1.684-10.221 7.2 4.994z"
        fill="#FFE5C2"
      />
      <path
        d="M102.273 141.171c2.032 3.167 5.535 4.605 7.858 4.955l-1.2-3.284-7.219-4.975.561 3.304z"
        fill="#FED2A4"
      />
      <path
        d="M102.97 126.462c2.883-1.769 6.677-.428 7.877 2.701a38.072 38.072 0 011.548 5.013c1.103 4.645-.29 9.697-2.4 10.163-2.109.467-8.593-1.263-10.257-7.811-1.026-4.042.193-8.201 3.232-10.066z"
        fill="#FFE5C2"
      />
      <path
        d="M101.403 136.721c.232 1.03-.271 2.001-1.103 2.196-.832.194-1.703-.506-1.916-1.516-.232-1.03.27-2.002 1.103-2.196.832-.194 1.703.486 1.916 1.516z"
        fill="#FFE5C2"
      />
      <path
        d="M103.415 148.049s-8.167-.331-10.025 1.399c-1.18 1.088 1.22 12.067 1.22 12.067s6.657 12.805 7.625 20.286c1.606 12.436 3.077 20.073 3.716 20.5 3.677 1.321 15.812-1.185 16.315-2.623 5.554-15.895-18.851-51.629-18.851-51.629z"
        fill="url(#prefix__paint6_linear_969:150)"
      />
      <path
        d="M142.896 174.397l5.419-22.987a1.306 1.306 0 011.278-1.011h9.851c.871 0 1.49.817 1.277 1.652l-5.98 22.618a1.303 1.303 0 01-1.22.972l-9.289.369a1.313 1.313 0 01-1.336-1.613z"
        fill="url(#prefix__paint7_linear_969:150)"
      />
      <path
        d="M151.896 173.796c-.425.077-1.103.233-1.587.408-.522.194-1.045.194-1.509.291-.91.195-1.568.331-1.549.292.02-.059-.193-.136.291-.311.561-.214 1.877-.408 2.69-.797.464-.214 1.451-.602 1.451-.602s1.394-.875 1.355-.972c-.116-.525-1.238-.175-1.819.194-.503.059-1.529.506-1.529.506s-2.284.447-2.342.136c-.019-.136 1.336-.564 2.613-1.205 1.181-.583 2.284-1.36 2.206-1.613-.135-.408-1.509.097-1.509.097s-.949.408-1.278.525c-.387.136-1.548.447-1.548.447s-4.142.913-4.161.602c-.039-.311.194-1.127.426-1.263.232-.136.813-.175 1.045-.33.232-.136.426-.525.271-.816-.155-.292-1.568-.039-1.568-.039s-1.142.213-1.761.738c-2.148 1.807-1.761 2.079-2.032 2.293-.329.272-5.98.894-5.98.894s-16.625 1.943-17.535 2.021l.581 5.558c.987.097 20.186-3.265 22.16-3.654 0 0 2.516.37 4.103.564 1.587.194 3.193-.33 3.638-.35.058 0 1.587-.136 3.406-.797 1.587-.563-.174-.738-.174-.738s-3.096.544-3.096.291c0-.194 1.722-.388 3.464-.991.445-.155.89-.311 1.297-.466 1.083-.389 1.161-1.127-.02-.913z"
        fill="#FFE5C2"
      />
      <path
        d="M114.04 172.552s19.393-.136 20.147 0c.736.136.949 5.13 1.316 7.151.117.641-20.456 2.953-24.018 2.273-3.89-.738-13.702-11.561-15.966-15.215-1.897-3.07-5.98-10.862-4.955-14.262.62-2.06 3.793-4.392 5.516-3.848 6.909 2.157 17.96 23.901 17.96 23.901z"
        fill="#86B1F2"
      />
      <path
        d="M157.993 163.38a.643.643 0 01.871.466c.077.408.077.894-.233 1.205-.619.622-4.722 1.069-4.722.466 0-.486 2.806-1.632 4.084-2.137z"
        fill="#FFE5C2"
      />
      <path
        d="M158.902 165.168s.464 1.515.116 1.554c-.348.02-4.761.642-4.993.136-.213-.524 4.296-1.962 4.877-1.69z"
        fill="#FFE5C2"
      />
      <path
        d="M159.096 166.898s.174.68-.174 1.088c-.348.427-4.471.427-4.664-.214-.213-.661 4.664-1.38 4.838-.874z"
        fill="#FFE5C2"
      />
      <path
        d="M158.148 168.452s.077.525-.078.777c-.174.253-3.232.408-3.638-.175-.407-.621 3.251-1.224 3.716-.602z"
        fill="#FFE5C2"
      />
      <path
        d="M157.664 133.262h-24.038c-3.154 0-5.709-2.565-5.709-5.732v-6.801c0-3.168 2.555-5.733 5.709-5.733h24.038c3.154 0 5.709 2.565 5.709 5.733v6.801c.019 3.167-2.555 5.732-5.709 5.732z"
        fill="#F1F4F9"
      />
      <path
        d="M139.219 130.756l11.225 17.858-4.645-17.858h-6.58z"
        fill="#F1F4F9"
      />
      <path
        d="M276.788 102.143l-.889-4.078s-2.932 1.85-4.652 4.513c-1.721 2.662-2.535 9.743-3.007 10.008-.492.264-5.901-8.063-6.846-9.385-1.173-1.624-1.608-3.494-2.345-4.91-.738-1.416-3.31-1.907-4.293-1.718-.984.17 1.343 4.324 1.343 4.324s5.862 10.178 7.829 15.748c1.948 5.571 8.075 8.233 9.039 7.44.984-.774 3.821-21.942 3.821-21.942z"
        fill="#FFE5C2"
      />
      <path
        d="M258.973 99.103s-.245-1.076-.907-1.284c-.662-.227-1.797-.019-1.873 0-.075.019-1.342-.66-1.342-.66l1.04 1.944s2.042 2.417 2.723 1.511c.7-.907.359-1.51.359-1.51zM254.492 97.233s-.738 3.758-.549 4.551c.189.793.662.359.757.189.359-.699.624-3.116.624-3.116l-.832-1.624z"
        fill="#FFE5C2"
      />
      <path
        d="M256.08 100.368s-.36 2.323-.719 2.606c-.227.17-.53.076-.643-.396-.095-.472.473-3.343.473-3.343l.889 1.133z"
        fill="#FFE5C2"
      />
      <path
        d="M258.236 106.978l1.229-.604-5.219-10.688a1.356 1.356 0 00-.624 1.831l4.614 9.461z"
        fill="url(#prefix__paint8_linear_969:150)"
      />
      <path
        d="M271.342 85.413c-.738-3.343-1.457-6.704.491-10.386 1.57-2.964 4.293-4.683 7.432-5.06 8.908-1.058 6.998 7.25 19.441 9.044 3.858.567 7.451 1.096 10.685 3.022 5.825 3.474 8.038 9.026 4.161 18.09-9.475 22.187-32.717 3.361-35.573-1.02-2.137-3.248-6.146-11.538-6.637-13.69z"
        fill="url(#prefix__paint9_linear_969:150)"
      />
      <path
        d="M306.971 225.58s.642 6.421 2.269 7.61c1.626 1.19 3.801 5.552 2.723 7.912-.34.737-1.021 1.171-1.796 1.398-2.289.698-4.728-.472-5.693-2.663-.548-1.227-1.021-2.851-1.116-4.91-.245-5.23-1.872-7.874-1.872-7.874l5.485-1.473zM281.913 228.205s-.51 6.496-.094 8.592c.227 1.133 1.475 3.456.889 4.513-.568 1.058-1.23 1.322-1.23 1.322l-14.788.246c-.813.019-.265-.51.378-.982 1.059-.775 1.929-1.303 5.314-2.927.227-.114 1.021-.623 1.324-.793.737-.454 1.475-1.152 2.118-1.87 1.059-1.171 1.154-6.892 1.154-6.892l4.935-1.209z"
        fill="#FFE5C2"
      />
      <path
        d="M270.358 141.986s-1.853 10.536-.643 17.428c1.23 6.912 7.092 70.905 7.092 70.905l6.525.359s1.361-26.738 1.115-30.458c-.245-3.72-2.174-9.932-2.174-9.932l.643-21.583-8.227-28.362-4.331 1.643z"
        fill="url(#prefix__paint10_linear_969:150)"
      />
      <path
        d="M289.516 139.777s5.144 7.704 6.354 14.067c1.229 6.383-.889 25.964 1.456 34.311 1.267 4.513 6.09 11.499 6.827 17.353.738 5.835 3.612 21.149 3.612 21.149l-6.524 1.284-15.148-38.219-11.877-34.537-3.044-17.788 18.344 2.38z"
        fill="url(#prefix__paint11_linear_969:150)"
      />
      <path
        d="M285.374 95.95s4.501.51 7.186 3.437c2.686 2.927-4.349 26.398-4.841 30.118-.492 3.72 4.009 12.387 4.009 12.387s-16.793 3.097-21.37.095c0 0-.491-26.739 0-29.382.492-2.663 4.558-13.464 5.541-14.521.984-1.058 9.475-2.134 9.475-2.134z"
        fill="url(#prefix__paint12_linear_969:150)"
      />
      <path
        d="M291.728 101.595c-.435-1.303-1.91-1.945-3.177-1.397-1.74.755-4.142 2.172-5.295 4.607-1.948 4.079-8.794 18.052-10.931 18.581-2.118.529-10.761-14.521-10.761-14.521s.246-2.662-.491-4.154c-.738-1.511-2.931-3.097-3.253-2.568-.321.529 1.229 1.85 1.229 2.134 0 .264-.643.434-1.55-.095-.889-.528-1.381-1.605-1.381-1.605s-1.475 2.663.076 5.061a312.811 312.811 0 002.193 3.361s10.023 22.225 13.692 22.489c3.404.246 23.072-21.545 19.649-31.893z"
        fill="#FFE5C2"
      />
      <path
        d="M283.104 86.905s.038.208.095.548c.302 1.624 1.475 7.024 2.25 8.516.946 1.794-3.271 7.516-7.319 6.817-1.21-.208 1.022-5.268.908-5.797-.208-1.02-.491-2.417-.775-3.852-.151-.812-.322-1.624-.473-2.399-.435-2.19-.794-3.984-.794-3.984l6.108.151z"
        fill="#FFE5C2"
      />
      <path
        d="M283.199 87.434c-.057-.34-.094-.548-.094-.548l-6.071-.15s.359 1.793.794 3.984c.152.774.322 1.586.473 2.38.946-.133 1.91-.586 2.893-1.455a7.531 7.531 0 002.194-3.267c-.075-.359-.132-.68-.189-.944z"
        fill="#FED2A4"
      />
      <path
        d="M279.455 72.478s2.477.34 4.274 2.663c1.796 2.322 3.725 9.48-.738 13.37-4.463 3.89-8.869-.51-9.531-5.194-.643-4.664-.511-11.009 5.995-10.839z"
        fill="#FFE5C2"
      />
      <path
        d="M272.042 81.713c.529 1.038 1.418 1.605 2.004 1.284.568-.321.606-1.436.076-2.455-.53-1.039-1.418-1.605-2.005-1.284-.586.32-.605 1.435-.075 2.455z"
        fill="#FFE5C2"
      />
      <path
        d="M278.698 72.365s3.82 6.817 6.562 7.119c2.743.32 3.102 3.928 3.102 3.928s-.984-8.328-3.688-9.952c-2.723-1.586-5.976-1.095-5.976-1.095z"
        fill="url(#prefix__paint13_linear_969:150)"
      />
      <path
        d="M284.353 81.108c-.227 1.209.227 2.323 1.002 2.474.775.151 1.589-.699 1.815-1.907.227-1.209-.226-2.323-1.002-2.474-.775-.151-1.588.699-1.815 1.907z"
        fill="#FFE5C2"
      />
      <path
        d="M304.001 238.686c3.555.642 5.919-1.417 7.149-2.984.907 1.756 1.456 3.947.794 5.382-.586 1.265-2.175 1.624-3.518 1.662a3.83 3.83 0 01-3.46-1.926 10.82 10.82 0 01-.965-2.134zM273.724 238.175c3.915 1 6.808.321 8.416-.321.416 1.17.964 2.662.681 3.512-.36 1.038-.87 1.284-1.324 1.284-3.574.094-13.087.227-15.81.264a.446.446 0 01-.34-.736c.662-.812 8.075-3.833 8.377-4.003z"
        fill="#112B46"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M266.898 57.695h-17.683c-3.896 0-7.054-3.154-7.054-7.044v-8.365c0-3.89 3.158-7.044 7.054-7.044h29.729c3.895 0 7.054 3.154 7.054 7.044v8.365c0 3.89-3.159 7.044-7.054 7.044h-5.886l3.238 9.685-9.398-9.685z"
        fill="#5384EE"
        fillOpacity={0.1}
      />
      <path
        d="M273.137 47.307c0 1.133.984 2.04 2.194 2.04 1.211 0 2.194-.926 2.194-2.04 0-1.133-.983-2.04-2.194-2.04-1.21 0-2.194.926-2.194 2.04zM264.099 49.346c-1.212 0-2.194-.913-2.194-2.039 0-1.126.982-2.04 2.194-2.04 1.211 0 2.193.914 2.193 2.04s-.982 2.04-2.193 2.04zM250.69 47.307c0 1.133.984 2.04 2.194 2.04s2.194-.926 2.194-2.04c0-1.133-.984-2.04-2.194-2.04s-2.194.926-2.194 2.04z"
        fill="#fff"
      />
      <path
        d="M257.972 146.139c-2.742-.789-2.818-.301-3.177-.413-.416-.132-3.745-4.601-3.745-4.601s-9.909-13.106-10.401-13.838l-3.12-16.975-9.38-.075s3.914 18.327 4.311 19.228c.379.901 16.075 16.918 17.399 18.382 0 0 .851 2.366 1.456 3.831.586 1.464 1.853 2.535 2.099 2.91.038.057.908 1.277 2.421 2.46 1.305 1.014.605-.563.605-.563s-2.023-2.347-1.778-2.479c.189-.112 1.192 1.239 2.629 2.385.359.3.738.582 1.059.845.889.695 1.626.319.832-.545-.283-.3-.756-.807-1.153-1.126-.435-.338-.7-.789-1.003-1.108-.624-.676-1.078-1.146-1.04-1.164.057-.019.038-.244.435.056.473.357 1.305 1.371 2.061 1.84.435.263 1.286.883 1.286.883s1.494.676 1.57.6c.435-.413-.435-1.164-1.059-1.445-.284-.395-1.21-1.014-1.21-1.014s-1.532-1.69-1.267-1.934c.113-.094 1.191.826 2.42 1.539 1.135.676 2.421 1.165 2.61.958.322-.357-.832-1.239-.832-1.239s-.851-.582-1.116-.789c-.302-.244-1.172-1.07-1.172-1.07s-2.856-3.023-2.591-3.23c.265-.206 1.153-.488 1.418-.357.246.113.568.583.813.714.246.113.7.056.927-.244.208-.301-.719-1.352-.719-1.352s-.794-.845-1.588-1.07zM242.389 231.273l-.34 5.859c-.114.751-.605 2.573-.605 3.249 0 2.535 11.384-2.16 8.831-3.286-.227-.094-1.437-.864-1.513-1.728-.113-1.333-.416-4.018-.416-4.018l-5.957-.076z"
        fill="#FFE5C2"
      />
      <path
        d="M250.256 237.094s-2.667.77-4.047.976c-1.362.188-3.177-1.446-4.142-.995-1.059.488-.964 5.277-.964 5.277s14.259.826 18.287-.451c1.608-.545-3.688-3.005-9.134-4.807z"
        fill="#112B46"
      />
      <path
        d="M217.332 231.837l-.341 5.859c-.113.751-.605 2.572-.605 3.248 0 2.535 11.385-2.159 8.832-3.286-.227-.094-1.797-1.446-1.873-2.31-.113-1.333-.056-3.455-.056-3.455l-5.957-.056z"
        fill="#FFE5C2"
      />
      <path
        d="M225.18 237.639s-2.667.77-4.047.976c-1.362.188-3.177-1.446-4.142-.995-1.059.488-1.116 4.863-1.116 4.863s15.243 1.09 18.817.076c1.645-.47-4.047-3.099-9.512-4.92z"
        fill="#112B46"
      />
      <path
        d="M235.353 147.303l4.804 2.065 10.117 47.975-.416 35.958-8.113.188s-3.763-31.452-3.763-33.893c0-2.441-17.474-42.661-17.474-42.661l14.845-9.632z"
        fill="url(#prefix__paint14_linear_969:150)"
      />
      <path
        d="M231.647 149.951s-3.536 36.952-3.536 43.186c0 6.234-2.874 41.103-2.874 41.103l-8.132-.038s-.587-10.627-1.457-26.268c-.132-2.441.171-7.399 0-9.933-1.267-18.233-3.763-35.395-2.572-42.211.19-1.089.549-3.962 1.249-4.675 5.333-5.464 17.322-1.164 17.322-1.164z"
        fill="url(#prefix__paint15_linear_969:150)"
      />
      <path
        d="M241.557 120.79l-9.834-.657s-.492-8.731-.492-12.9c0-5.051-1.551-12.505-.946-12.355 1.702.394 4.634 1.953 5.995 3.83 4.728 6.535 5.277 22.082 5.277 22.082z"
        fill="url(#prefix__paint16_linear_969:150)"
      />
      <path
        d="M218.258 94.671s-5.616.77-7.092 1.86c-1.664 1.22-3.517 3.923-3.517 3.923s6.884 32.334 6.903 37.761c.018 4.206-1.494 14.533-1.494 14.533s20.821 1.296 28.688-1.784c0 0-2.496-12.28-2.496-15.509 0-3.23-2.969-32.578-3.65-34.625-.624-1.859-1.74-5.201-5.541-6.027-3.782-.808-11.801-.132-11.801-.132z"
        fill="url(#prefix__paint17_linear_969:150)"
      />
      <path
        d="M219.789 85.979l-1.38 9.032s5.068 10.741 8.094 9.821c2.534-.789.983-10.29.983-10.29s-1.38-5.108-.964-7.662c.397-2.572-6.733-.901-6.733-.901z"
        fill="#FFE5C2"
      />
      <path
        d="M226.503 86.861c.416-2.554-6.713-.883-6.713-.883l-.114.752c.208.507.473.92.776 1.258 1.588 1.69 4.084 2.704 6.24 2.779-.227-1.371-.359-2.854-.189-3.906z"
        fill="#FED2A4"
      />
      <path
        d="M218.617 73.21c-.208-.075-1.608 10.29 1.059 13.125 2.685 2.855 7.905 3.775 9.796 1.54 2.08-2.478 1.967-13.614-2.194-16.543-4.179-2.93-8.661 1.878-8.661 1.878z"
        fill="#FFE5C2"
      />
      <path
        d="M225.671 65.793c-3.234.657-5.314 2.59-6.089 3.417-1.456.17-3.461.977-5.503 4.319-2.534 4.131-.662 8.694 2.288 10.947 2.95 2.235 2.912 4.845 2.912 4.845l.454-1.352c.341-1.014.492-2.084.359-3.155-.075-.638-.208-1.333-.472-1.896-.7-1.502 1.513-2.498 2.137-3.737.472-.958.51-3.155 1.267-4.75l.907-1.221s2.081-1.596 4.123-1.146c2.062.47 1.759-7.098-2.383-6.271z"
        fill="url(#prefix__paint18_linear_969:150)"
      />
      <path
        d="M221.643 81.021c.019 1.258-.567 2.291-1.305 2.31-.737.019-1.361-.995-1.38-2.272-.019-1.258.567-2.291 1.305-2.31.737 0 1.342 1.014 1.38 2.272z"
        fill="#FFE5C2"
      />
      <path
        d="M232.554 127.55l.133.77a.756.756 0 00.851.601l16.093-2.76a.753.753 0 00.605-.845l-.132-.77a.758.758 0 00-.851-.601l-16.093 2.76a.754.754 0 00-.606.845z"
        fill="url(#prefix__paint19_linear_969:150)"
      />
      <path
        d="M209.748 98.84c1.891-.056 3.555 12.299 2.364 13.519-1.192 1.221-1.419 16.449-1.419 16.449l17.815-.432c.34-.225 4.085-2.723 4.614-3.267.568-.563 3.272-.282 3.99.507.719.788-1.607.15-2.231.563-.643.432.473 1.502.473 1.502s4.52.658 5.389-.281c.889-.92 3.234-2.498 3.556-1.578.17.47-1.04 1.934-1.721 2.535.851-.638 1.872-1.314 2.231-1.183.643.207.379 1.014.019 1.258-.34.244-3.933 2.948-4.482 3.136-.548.188-8.869.394-10.325.714 0 0-23.488 5.314-24.812 4.976-1.173-.282-2.345-7.755-2.345-13.463-.019-5.709.983-24.786 6.884-24.955z"
        fill="#FFE5C2"
      />
      <path
        d="M212.206 96.023s-3.291.752-6.676 4.544c-3.082 3.437-5.03 22.72-5.03 22.72l10.647 1.709.851-9.332 2.856-4.262-2.648-15.379z"
        fill="url(#prefix__paint20_linear_969:150)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_969:150"
          x1={217.5}
          y1={109.257}
          x2={217.5}
          y2={242.99}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_969:150"
          x1={109.535}
          y1={155.034}
          x2={128.01}
          y2={196.786}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear_969:150"
          x1={131.835}
          y1={160.803}
          x2={131.835}
          y2={239.124}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear_969:150"
          x1={103.384}
          y1={120.861}
          x2={103.384}
          y2={143.12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear_969:150"
          x1={107.833}
          y1={208.573}
          x2={163.632}
          y2={208.573}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear_969:150"
          x1={127.762}
          y1={201.857}
          x2={182.716}
          y2={201.857}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear_969:150"
          x1={91.66}
          y1={156.357}
          x2={114.354}
          y2={204.387}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear_969:150"
          x1={151.813}
          y1={150.399}
          x2={151.813}
          y2={176.011}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear_969:150"
          x1={256.471}
          y1={95.686}
          x2={256.471}
          y2={106.978}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear_969:150"
          x1={292.964}
          y1={69.974}
          x2={292.964}
          y2={110.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear_969:150"
          x1={284.467}
          y1={185.553}
          x2={269.292}
          y2={185.553}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear_969:150"
          x1={307.74}
          y1={182.712}
          x2={271.14}
          y2={182.712}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear_969:150"
          x1={294.246}
          y1={103.17}
          x2={273.103}
          y2={142.787}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="prefix__paint13_linear_969:150"
          x1={283.515}
          y1={72.32}
          x2={283.515}
          y2={83.363}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint14_linear_969:150"
          x1={250.254}
          y1={190.437}
          x2={220.482}
          y2={190.437}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint15_linear_969:150"
          x1={231.634}
          y1={191.332}
          x2={212.747}
          y2={191.332}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint16_linear_969:150"
          x1={242.09}
          y1={98.828}
          x2={229.902}
          y2={119.503}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="prefix__paint17_linear_969:150"
          x1={243.34}
          y1={103.344}
          x2={219.892}
          y2={155.893}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="prefix__paint18_linear_969:150"
          x1={221.069}
          y1={65.787}
          x2={221.069}
          y2={89.219}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint19_linear_969:150"
          x1={241.395}
          y1={123.938}
          x2={241.395}
          y2={128.929}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint20_linear_969:150"
          x1={215.525}
          y1={100.441}
          x2={202.72}
          y2={124.88}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <filter
          id="prefix__filter0_dd_969:150"
          x={50.5}
          y={0}
          width={98}
          height={100.997}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={6} dy={2} />
          <feGaussianBlur stdDeviation={10} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_969:150"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="effect1_dropShadow_969:150"
            result="effect2_dropShadow_969:150"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_969:150"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default FeedEmptyState;
