const errorMsg = {
  WrongPasswordException: 'WrongPasswordException',
  UserIsBlockedException: 'UserIsBlockedException',
  PageAccessException: 'PageAccessException',
  RateLimiterExceedException: 'RateLimiterExceedException',
  EmailNotFoundException: 'EmailNotFoundException',
  AccessDeniedException: 'AccessDeniedException',
  WrongCredentialException: 'WrongCredentialException',
  MaximumRolePerPageExceedException: 'MaximumRolePerPageExceedException',
} as const;

export default errorMsg;
