import { QueryKeys, useAuthUser, useBusinessPage } from '@lobox/utils';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import get from 'lodash/get';
import { useQueryClient } from '@tanstack/react-query';
import type { UserType, BeforeCachePageDetailType } from '@lobox/utils';

type AppObjectKey = {
  pageKey: string | Array<string>;
  userKey: string | Array<string>;
};

interface UseGetAppObjectType {
  isBusinessApp: boolean;
  getAppObjectPropValue: (props: AppObjectKey) => any;
  authUser?: UserType;
  businessPage?: BeforeCachePageDetailType;
  updateAuthUserQueryCache: (updater: (arg: UserType) => void) => void;
  updateBusinessPageQueryCache: (
    updater: (arg: BeforeCachePageDetailType) => void
  ) => void;
  reFetchAppObject: () => void;
  isLoading?: boolean;
  isAuthUserBusinessOwner: boolean;
  checkIsBusinessOwner: (id: string) => boolean;
  checkIsAuthUser: (id: string) => boolean;
}

const useGetAppObject = (): UseGetAppObjectType => {
  const queryClient = useQueryClient();
  const { data: authUser, isLoading: isLoadingAuthUser } = useAuthUser();
  const { data: businessPage, isLoading: isLoadingBusiness } = useBusinessPage({
    isEnabled: isBusinessApp,
  });
  const isAuthUserBusinessOwner = businessPage?.ownerId === authUser?.id;
  const checkIsBusinessOwner = (id: string): boolean =>
    businessPage?.ownerId === id;
  const checkIsAuthUser = (id: string): boolean => authUser?.id === id;

  const getAppObjectPropValue = ({ userKey, pageKey }: AppObjectKey) =>
    isBusinessApp ? get(businessPage, pageKey) : get(authUser, userKey);

  const updateAuthUserQueryCache = (updater: (arg: UserType) => void): void =>
    queryClient.setQueryData([QueryKeys.authUser], (oldData: any) =>
      updater(oldData)
    );

  const updateBusinessPageQueryCache = (
    updater: (arg: BeforeCachePageDetailType) => void
  ): void =>
    queryClient.setQueryData([QueryKeys.businessPage], (oldData: any) =>
      updater(oldData)
    );

  const reFetchAppObject = async () => {
    const key = isBusinessApp ? [QueryKeys.businessPage] : [QueryKeys.authUser];
    await queryClient.refetchQueries(key);
  };

  return {
    getAppObjectPropValue,
    isBusinessApp,
    authUser,
    businessPage,
    updateAuthUserQueryCache,
    updateBusinessPageQueryCache,
    reFetchAppObject,
    isLoading: isLoadingAuthUser || isLoadingBusiness,
    isAuthUserBusinessOwner,
    checkIsBusinessOwner,
    checkIsAuthUser,
  };
};

export default useGetAppObject;
