import classes from './Tooltip.module.scss';

import React from 'react';
import { Popper } from '@material-ui/core';
import { isElementVisible } from '@lobox/utils';
import type { PopperProps } from '@material-ui/core';
import type { TypographyProps } from '../Typography';
import Flex from '../Flex';
import cnj from '../utils/cnj';
import Typography from '../Typography';

export const arrowWidth = 12;

export interface TooltipProps extends Partial<PopperProps> {
  trigger?: React.ReactNode;
  triggerWrapperClassName?: string;
  arrowClassName?: string;
  disabled?: boolean;
  tooltipWrapperProps?: Partial<TypographyProps>;
}

const ParentTooltip = ({
  keepMounted,
  children,
  onMouseLeave,
}: Partial<PopperProps>) =>
  keepMounted ? (
    <Flex onMouseLeave={onMouseLeave}>{children}</Flex>
  ) : (
    <>{children}</>
  );

const Tooltip: React.FC<TooltipProps> = ({
  open,
  children,
  trigger,
  triggerWrapperClassName,
  arrowClassName,
  tooltipWrapperProps,
  modifiers,
  disabled = false,
  disablePortal = false,
  keepMounted,
  ...rest
}) => {
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const [visible, setVisible] = React.useState(disablePortal);
  const anchorElRefItem = React.useRef<boolean>(false);
  const anchorElRefPopper = React.useRef<boolean>(false);
  const refItem = React.useRef<any>(null);
  const hasOpenProp = typeof open === 'boolean';

  const handleMouseEnterItem = (evt: React.MouseEvent) => {
    if (hasOpenProp || !isElementVisible(evt.target)) {
      return;
    }
    if (!disablePortal) {
      setTimeout(() => {
        setVisible(() => true);
      }, 50);
    }
    setAnchor(() => evt.target as HTMLElement);
    anchorElRefItem.current = true;
  };

  const handleMouseLeaveItem = () => {
    if (hasOpenProp) {
      return;
    }
    if (!keepMounted) {
      if (!disablePortal) {
        setVisible(() => false);
      }
      setAnchor(() => null);
    } else {
      setTimeout(() => {
        anchorElRefItem.current = false;
        if (!anchorElRefPopper.current) {
          if (!disablePortal) {
            setVisible(() => false);
          }
          setAnchor(() => null);
        }
      }, 150);
    }
  };

  const handleMouseEnterPopper = () => {
    if (hasOpenProp || !keepMounted) {
      return;
    }
    anchorElRefPopper.current = true;
    if (!disablePortal) {
      setTimeout(() => {
        setVisible(() => true);
      }, 50);
    }
    setAnchor(() => refItem.current as HTMLElement);
  };

  const handleMouseLeavePopper = () => {
    if (hasOpenProp || !keepMounted) {
      return;
    }
    anchorElRefPopper.current = false;
    if (!anchorElRefItem.current) {
      if (!disablePortal) {
        setVisible(() => false);
      }
      setAnchor(() => null);
    }
  };

  return (
    <ParentTooltip
      onMouseLeave={handleMouseLeaveItem}
      keepMounted={keepMounted}
    >
      <Flex
        onMouseEnter={handleMouseEnterItem}
        onMouseLeave={keepMounted ? undefined : handleMouseLeaveItem}
        className={triggerWrapperClassName}
        ref={refItem}
      >
        {trigger}
      </Flex>
      {!disabled && (
        <Popper
          open={hasOpenProp ? open || false : Boolean(anchor)}
          anchorEl={anchor}
          onMouseEnter={handleMouseEnterPopper}
          onMouseLeave={handleMouseLeavePopper}
          className={cnj(classes.tooltip, { [classes.notVisible]: !visible })}
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef,
            },
            ...modifiers,
          }}
          disablePortal={disablePortal}
          transition
          {...rest}
        >
          <>
            <div
              ref={setArrowRef}
              className={cnj('tooltip-arrow', arrowClassName)}
            />
            {typeof children === 'string' ? (
              <Typography
                color="tooltipText"
                className={classes.text}
                {...tooltipWrapperProps}
              >
                {children}
              </Typography>
            ) : (
              children
            )}
          </>
        </Popper>
      )}
    </ParentTooltip>
  );
};

export default Tooltip;
