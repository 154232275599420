export function getFirstBackgroundParent(
  node: HTMLElement | ParentNode | null,
  forceBackground: boolean = true
) {
  if (!node) return null;

  const computedStyle = getComputedStyle(node as HTMLElement);
  let backgroundColor = computedStyle.getPropertyValue('background-color');
  if (forceBackground) backgroundColor = '';

  if (
    ['', 'transparent', 'rgba(0, 0, 0, 0)']?.every(
      (item) => item !== backgroundColor
    )
  ) {
    return backgroundColor;
  } else {
    return getFirstBackgroundParent(node.parentNode, false);
  }
}
