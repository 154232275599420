// @ts-nocheck
import {
  getPopularHashtags as getHashtagsPop,
  getUsersToMention,
  suggestHashtags,
} from '@lobox/utils';
import emojiRegex from 'emoji-regex';

const emoReg = emojiRegex();

export const getUsers = ({ text }: any) =>
  getUsersToMention({
    text,
  });

export const getHashtags = async ({ text }) => {
  try {
    const { content } = await suggestHashtags({
      text,
    });
    const d = content.map((x) => ({ value: x.title }));
    return d;
  } catch (error) {
    return [];
  }
};

export const getPopularHashtags = async () => {
  try {
    const { content } = await getHashtagsPop();
    const d = content.map((x) => ({ ...x, value: x.id }));
    return d;
  } catch (error) {
    return [];
  }
};

export const getRichTextMentions = (html: string): string[] => {
  if (!html) return [];
  const container = document.createElement('div');
  container.innerHTML = html;
  const mentionsElement = container.getElementsByClassName('mention');
  const arrMentionsElements = [].slice.call(mentionsElement);
  const mentions = arrMentionsElements.map((x) => {
    if (x.dataset.denotationChar === '') {
      return x.dataset.userid;
    }
    return undefined;
  });
  return mentions.filter(Boolean);
};

export const getRichTextHashtags = (html: string): string[] => {
  if (!html) return [];
  const container = document.createElement('div');
  container.innerHTML = html;
  const hashtagsElement = container.getElementsByClassName('mention');
  const arrHashtagsElements = [].slice.call(hashtagsElement);
  const hashtags = arrHashtagsElements.map((x) => {
    if (x.dataset.denotationChar === '#') {
      return x.dataset.value;
    }
    return undefined;
  });
  return hashtags.filter(Boolean);
};

export const detectNewHashtags = (html: string): string => {
  if (!html?.length) return null;
  return html.replace(/#[^ !@#$%^&*(),.?";:{}|<>-]+/giu, (_hash) => {
    const hash = _hash.split('#')[1];
    return `<span class="mention" data-index="0" data-denotation-char="#" data-id="${hash}" data-value="${hash}" data-userid="${hash}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">#</span>${hash}</span>&#xFEFF;</span>`;
  });
};

export const sanitizeEmojies = (html: string): string =>
  (html || '').replace(
    emoReg,
    (match) => `<span class="emoji">${match}</span>`
  );

const urlRegex =
  /((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s<]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s<]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s<]{2,}|www\.[a-zA-Z0-9]+\.[^\s<]{2,}))/gi;

export const linkify = (text) => {
  if (!text?.length) {
    return '';
  }

  return text.replace(urlRegex, function (url) {
    const prefix = 'https://';
    let link = url;
    if (link.substring(0, prefix.length) !== prefix) {
      link = prefix + link;
    }
    return `<a href="${link}" class="richTextAnchor" target="_blank">${url}</a>`;
  });
};

export const getInnerText = (html: string): string[] => {
  if (!html) return [];
  const container = document.createElement('div');
  container.innerHTML = html;
  return container.innerText;
};

export function countLines(html: string): number {
  if (!html) return [];
  const container = document.createElement('div');
  container.innerHTML = html;

  const el = container.getElementsByClassName('content');

  // Get total height of the content
  var divHeight = el.offsetHeight;

  // object.style.lineHeight, returns
  // the lineHeight property
  // height of one line
  var lineHeight = parseInt(el.style.lineHeight);

  return divHeight / lineHeight;
}

export default {};
