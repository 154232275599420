import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '../Tooltip';
import type { TypographyProps } from './index';
import Typography from './index';
import cnj from '../utils/cnj';
import classes from './OverflowTip.module.scss';

const OverflowTip = (props: TypographyProps) => {
  const { children, ...rest } = props;
  const textElement = useRef<any>(null);
  const [isCutTextInOverflow, setIsCutTextInOverflow] = useState(false);

  const compareSize = () => {
    if (textElement && textElement.current) {
      const compare =
        textElement.current.scrollWidth > textElement.current.clientWidth;
      setIsCutTextInOverflow(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, [compareSize, textElement.current]);

  return (
    <Tooltip
      placement="top"
      disabled={!isCutTextInOverflow}
      triggerWrapperClassName={classes.wrapper}
      trigger={
        <Typography
          ref={textElement}
          {...rest}
          className={cnj(classes.text, rest?.className)}
        >
          {children}
        </Typography>
      }
    >
      {children}
    </Tooltip>
  );
};

export default OverflowTip;
